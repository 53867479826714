import { BrowserRouter, Route, Routes } from "react-router-dom"

import { LoginFactory } from "main/factory/pages/safety/login-factory"
import ErrorPage from "presentation/pages/error/error-page"
import ErrorAuthenticationTokenPage from "presentation/pages/error/error-authentication-token-page"
import { ResetPasswordFactory } from "main/factory/pages/safety/reset-password-factory"

export function Public() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='*' element={<ErrorAuthenticationTokenPage />} />
                {/* <Route path='/error-token' element={<ErrorAuthenticationTokenPage />} /> */}
                <Route path="/" element={<LoginFactory />} />
                <Route path="/reset-password"  element={<ResetPasswordFactory />}/>
            </Routes>
        </BrowserRouter>
    )
}

export default Public