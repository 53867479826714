export function setLocalStorege(key: string, value: object): void {
    if (value)
        localStorage.setItem(key, JSON.stringify(value))
    else
        localStorage.removeItem(key)
}

export function getLocalStorage(key: string): any {
    return JSON.parse(localStorage.getItem(key)!)
}

export function updateLocalStorege(key: string, value: object): void {
    if (key) {
        localStorage.removeItem(key)
        localStorage.setItem(key, JSON.stringify(value))
    }
}