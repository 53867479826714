/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
import Select from 'react-select'
import { useEffect, useState } from "react"
import { Col, Form, Row } from "react-bootstrap"
import { UseAuth } from "presentation/hook/auth-hook"
import { AuthUseCase } from "data/useCase/auth-usecase"
import { RestUseCase } from "data/useCase/rest-usecase"
import ChangeUserPage from "presentation/pages/safety/change-user-page"
import { getLocalStorage, setLocalStorege } from "data/cache/localstorage-cache"
import { SelectOptionInterface } from "domain/entity/interface/select-option-interface"
import ErrorNotAuthorizedPage from "presentation/pages/error/error-not-authorized-page"
import { useNavigate } from 'react-router-dom'
import { HeaderNavegationList } from 'main/adapter/headerNavigation/header-navegation-adapter'
import { getEnv } from 'main/helper/window-helper'

export const ChangeUserFactory: React.FC = () => {

    let navigate = useNavigate()

    /** CONFIGURACAO */
    const ENDPOINT_USERS = getEnv("REACT_APP_END_POINT_USER")!
    const ENDPOINT_COMPANIES = getEnv("REACT_APP_END_POINT_COMPANIES")!
    const ENDPOINT_SWITCH_USER = getEnv("REACT_APP_END_POINT_SWITCH_USER")!
    const LOCALSTORAGE_TOKEN_USER_CHANGE = getEnv("REACT_APP_LOCALSTORAGE_TOKEN_USER_CHANGE")!
    const LOCALSTORAGE_TOKEN_USER_LOGIN = getEnv("REACT_APP_LOCALSTORAGE_TOKEN_USER_LOGIN")!
    const tokenLocalStorageLogin: { token: string } = getLocalStorage(LOCALSTORAGE_TOKEN_USER_LOGIN)

    /** SELECT */
    const [user, updateUser] = useState<SelectOptionInterface>()
    const [company, updateCompany] = useState<SelectOptionInterface>()
    const [listModelCompanies, updateListModelCompanies] = useState<SelectOptionInterface[]>([])
    const [listModelUsers, updateListModelUsers] = useState<SelectOptionInterface[]>([])
    const [listModelRole] = useState<SelectOptionInterface[]>([
        { label: "ADMIN", value: "ADMIN" },
        { label: "GUEST", value: "GUEST" },
        { label: "COMPRAS_GESTOR", value: "COMPRAS_GESTOR" },
        { label: "COMPRAS_OPERACIONAL", value: "COMPRAS_OPERACIONAL" },
        { label: "SCRAP_ADMINISTRADOR", value: "SCRAP_ADMINISTRADOR" },
        { label: "SCRAP_COMPRADOR", value: "SCRAP_COMPRADOR" },
        { label: "SCRAP_AUDITOR", value: "SCRAP_AUDITOR" },
        { label: "SCRAP_CONSULTOR", value: "SCRAP_CONSULTOR" },
        { label: "SCRAP_FINANCEIRO", value: "SCRAP_FINANCEIRO" },
        { label: "SCRAP_VENDAS", value: "SCRAP_VENDAS" }
    ])

    function changeCompaniesSelect(company: SelectOptionInterface) { listAllUserByCompanies(company); updateCompany(company) }
    function changeUserSelect(user: SelectOptionInterface) { updateUser(user) }
    function changeRoleSelect(role: SelectOptionInterface) { listAllUserByCompanies(company!, role) }

    /** CHAMADA BACKEND */
    async function listAllCompanies(): Promise<any> {
        await new RestUseCase(ENDPOINT_COMPANIES).Get()
            .then((response) => {
                if (response.statusCode !== 500 && response.statusCode !== undefined) {
                    updateListModelCompanies(response.body.map((value: any) => { return { value: value.id, label: value.nome, } }))
                }
            })
    }
    async function listAllUserByCompanies(company: SelectOptionInterface, role?: SelectOptionInterface): Promise<any> {
        let query = `?empresa=${company?.value}`

        if (role)
            query += `&roles=${role.value}`

        await new RestUseCase(ENDPOINT_USERS + query).Get()
            .then((response) => {
                updateListModelUsers(response.body.map((value: any) => { return { value: value.id, label: value.nome } }))
            })
    }
    async function changeUser(): Promise<any> {
        if (user !== undefined) {
            await new AuthUseCase(ENDPOINT_SWITCH_USER + `?id=${user?.value}`)
                .Post({ headers: { Authorization: `Bearer ${tokenLocalStorageLogin.token}` } })
                .then(response => {
                    setLocalStorege(LOCALSTORAGE_TOKEN_USER_CHANGE, response.body)
                })
            navigate("/")
            location.reload()
        }
    }

    /** INICIO */
    useEffect(() => {
        listAllCompanies()
    }, [])

    /** VERIFICA PERMISSAO DE ACESSO */
    const { loggedUserData } = UseAuth()
    const [showScreen, updateShowScreen] = useState<boolean>(false)
    useEffect(() => {
        const roleScreen = HeaderNavegationList.filter(header => header.navTitle === "Segurança")[0].navItem.filter(item => item.title === "Troca de Usuário")[0].role

        if (loggedUserData?.role) {
            loggedUserData.role.forEach(value => {
                if (roleScreen.includes(value))
                    updateShowScreen(true)
            })
        }
    }, [loggedUserData])

    /** RENDER */
    return (
        <>
            {showScreen ?
                <ChangeUserPage
                    titlePage={"Troca de Usuário"}
                    change={changeUser}
                    children={{
                        header: undefined,
                        body: <>
                            <Row>
                                <Col>
                                    <Form.Label>Empresa:</Form.Label>
                                    <Select options={listModelCompanies} onChange={val => { changeCompaniesSelect(val!) }} />
                                </Col>
                                <Col>
                                    <Form.Label>Perfil:</Form.Label>
                                    <Select isDisabled={company ? false : true} options={listModelRole} onChange={val => { changeRoleSelect(val!) }} />
                                </Col>
                                <Col>
                                    <Form.Label>Usuário:</Form.Label>
                                    <Select isDisabled={company ? false : true} options={listModelUsers} onChange={val => { changeUserSelect(val!) }} />
                                </Col>
                            </Row>
                        </>,
                        footer: undefined
                    }}
                />
                :
                <ErrorNotAuthorizedPage />
            }
        </>
    )

}
