/* eslint-disable react-hooks/exhaustive-deps */
import Select from 'react-select';
import { Controller, useForm } from 'react-hook-form';
import { useCallback, useEffect, useState } from 'react';
import { Button, Card, Col, Container, Form, Nav, Row } from 'react-bootstrap';

import { CrudInterface } from 'domain/entity/interface/crud-interface';
import { SelectOptionInterface } from 'domain/entity/interface/select-option-interface';
import { ConfigurationEntity, ConfigurationInputs } from 'domain/entity/configuration-entity';

import { UseAlert } from 'presentation/hook/alert-hook';

import { SpinnerSC } from 'presentation/style/spinner/spinner-styled';
import { SCDesctiption } from 'presentation/style/typography/titlePage';

import { Footer } from 'presentation/components/footer/footer-component';
import { Headers } from 'presentation/components/header/headers-component';
import { SelectCompanie } from 'presentation/components/select/companie-select-component';
import HeaderCrud from 'presentation/components/header/header-crud-component';
import { EntityField } from 'domain/entity/field/crud/field-crud-entity';
import { SelectFilial } from 'presentation/components/select/filial-select-component';
import { getEnv } from '../../../main/helper/window-helper';
import { Filial } from '../../../data/useCase/filial-usecase';
import { SortByKey } from '../../../main/helper/arrays-helper';

type Props = {
  title: string;
  entityFields: EntityField[];

  navItemsField: { label: string; key: string; active: boolean }[];

  tableHeader: any[];
  tableBody: any[];

  selectItensCurva: SelectOptionInterface[];
  selectItensNivel: SelectOptionInterface[];
  selectItensPontuacao: SelectOptionInterface[];
  selectItensColuns: SelectOptionInterface[];

  changeSelectItensCurva: (item: any) => any;
  changeSelectItensNivel: (item: any) => any;
  changeSelectItensPontuacao: (item: any) => any;
  changeSelectItensColuns: (item: any) => any;

  onChangeSaveItens: (value: string) => any;
};

const CurveTurnPage: React.FC<Props> = ({
  title,
  entityFields,
  navItemsField,
  tableHeader,
  tableBody,
  selectItensCurva,
  selectItensNivel,
  selectItensPontuacao,
  selectItensColuns,
  changeSelectItensCurva,
  changeSelectItensNivel,
  changeSelectItensPontuacao,
  changeSelectItensColuns,
  onChangeSaveItens,
}: Props) => {
  function BoxSaveIten() {
    const [valueChange, updateValueChange] = useState('');

    return (
      <>
        <Col>
          <Form.Control type="text" onChange={(event) => updateValueChange(event.target.value)} placeholder="digite um valor " />
        </Col>
        <Col>
          <Button onClick={() => onChangeSaveItens(valueChange)}>Salvar</Button>
        </Col>
      </>
    );
  }

  function SelectWithLableSelectAll(params: { passOnTheValue: any; defaultValue: SelectOptionInterface[]; placeholder: string }) {
    const [options, updateOption] = useState<SelectOptionInterface[]>(params.defaultValue);
    const [selectedOptions, setSelectedOptions] = useState<SelectOptionInterface[]>([]);
    const [selectAll, setSelectAll] = useState<boolean>(false);

    const handleSelectAll = () => {
      if (selectAll) {
        setSelectedOptions([]);
      } else {
        setSelectedOptions(options);
      }
      setSelectAll(!selectAll);
      params.passOnTheValue(options);
    };

    const handleOptionChange = (selected: any) => {
      setSelectedOptions(selected as SelectOptionInterface[]);
      setSelectAll((selected as SelectOptionInterface[]).length === options.length);
      params.passOnTheValue(selected);
    };

    return (
      <>
        <Select
          options={options}
          defaultValue={params.defaultValue}
          onChange={(val) => handleOptionChange(val)}
          placeholder={params.placeholder}
          isMulti={true}
          value={selectedOptions}
        />
        <label>
          <input type="checkbox" checked={selectAll} onChange={handleSelectAll} />
          Todos
        </label>
      </>
    );
  }

  /**
   *
   * RENDER
   *
   */
  return (
    <>
      <p className="mb-0 text-center">
        <small>Selecione a(s) Curva(s), o(s) Nível(s) ou a(s) Pontuação(s) desejada, </small>
      </p>
      <p className="mt-0 text-center">
        <small>depois selecione a(s) coluna(s) que deseja alterar, digite um valor e salve</small>
      </p>
      <Row>
        <Col></Col>
        <Col>
          <SelectWithLableSelectAll passOnTheValue={changeSelectItensCurva} defaultValue={selectItensCurva} placeholder={'Curva'} />
        </Col>
        <Col>
          <SelectWithLableSelectAll passOnTheValue={changeSelectItensNivel} defaultValue={selectItensNivel} placeholder={'Nível'} />
        </Col>
        <Col>
          <SelectWithLableSelectAll passOnTheValue={changeSelectItensPontuacao} defaultValue={selectItensPontuacao} placeholder={'Pontuação'} />
        </Col>
        <Col>
          <Select options={selectItensColuns} onChange={(val) => changeSelectItensColuns(val)} placeholder="Coluna" isMulti={false} />
        </Col>
        <BoxSaveIten />
      </Row>
    </>
  );
};

export default CurveTurnPage;
