import React, { useCallback, useEffect, useRef, useState } from 'react';
import { SpinnerSC } from 'presentation/style/spinner/spinner-styled';
import { Headers } from 'presentation/components/header/headers-component';
import { TitleGroupComponent } from '../../components/title/title-group-component';
import { Button, Col, Row, Form, Modal } from 'react-bootstrap';
import SelectCompanyUsers from 'presentation/components/filter/select-company-users';
import { RestUseCase } from 'data/useCase/rest-usecase';
import { getEnv } from 'main/helper/window-helper';
import JoditEditor from 'jodit-react';
import { FaQuestionCircle } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';
import { TableHeaderFixedStyled } from 'presentation/style/table/table-header-fixed-styled';
import { UseAlert } from "presentation/hook/alert-hook"

type Props = {
    titlePage: string;
}

const RegraConsultorOnlinePage: React.FC<Props> = ({ titlePage }) => {
    interface FilterData {
        id: number;
        nome: string;
    }

    interface PlanejamentoAutoData {
        id: number;
        nome: string;
        mensagem: string;
    }

    interface ConsultorOnlineTab{
        id: number;
        idAnalise: number;
        intervaloDiasExecucao: number;
        intervaloExecucao: number;
        mensagem: string;
        emails: string;
        nome: string;
        percentualMinimoEstoqueCustoMedio: number;
        percentualMinimoEstoqueReposicao: number;
        quantidadeDiasUltimaCarga: number;
        quantidadeMinimaItens: number;
        ultimaExecucao: number;
        valorMinimoCustoMedio: number;
        valorMinimoReposicao: number;
        dataAlteracao: string;
    }

    interface JoditEditorRef extends HTMLDivElement {
        setEditorValue: (value: string) => void;
        on: (eventName: string, callback: (newContent: string) => void) => void;
    }    

    const [showModal, setShowModal] = useState(false);
    const [statusCrud, setStatusCrud] = useState('post'); //post, patch, delete
    const [loading, updateLoading] = useState<boolean>(false);
    const [selectedCompanyId, setSelectedCompanyId] = useState<string | null>(null);
    const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
    const [nome, setNome] = useState('');
    const [emails, setEmails] = useState('');
    const [intervaloDias, setIntervaloDias] = useState<number | 0>(0);
    const [quantidadeDiasUltimaCarga, setQuantidadeDiasUltimaCarga] = useState<number | 0>(0);
    const [percentualMinimoEstoqueCustoMedio, setPercentualMinimoEstoqueCustoMedio] = useState<number | 0>(0);
    const [percentualMinimoEstoqueReposicao, setPercentualMinimoEstoqueReposicao] = useState<number | 0>(0);
    const [valorMinimoCustoMedio, setValorMinimoCustoMedio] = useState<number | 0>(0);
    const [valorMinimoReposicao, setValorMinimoReposicao] = useState<number | 0>(0);
    const [quantidadeMinimaItens, setQuantidadeMinimaItens] = useState<number | 0>(0);
    const [idAnalise, setIdAnalise] = useState<number | 0>(0);
    const [filterData, setFilterData] = useState<FilterData[]>([]);
    const [planejamentoAutoData, setPlanejamentoAutoData] = useState<PlanejamentoAutoData[]>([]);
    const [selectedMensagemId, setSelectedMensagemId] = useState<number | 0>(0);
    const [message, setMessage] = useState('');
    const [tabBody, setTabBody] = useState<React.ReactNode>(
        <div
            className='d-flex align-items-center justify-content-center'
            style={{ height: '60vh', backgroundColor: '#fff', margin: '10px 15px 10px 15px' }}
        >
            verifique seus paramentros de busca...
        </div>
    );     
    
    const ENDPOINT_ANALYTICAL = getEnv('REACT_APP_END_POINT_ANALYTICAL')!;    
    const ENDPOINT_CRUD = getEnv("REACT_APP_END_POINT_ONLINE_CONSULTANT")!;

    const editorRef = useRef<any>(null);

    const tooltipVariaveis = `
        Variáveis:<br/>
        {nome_empresa}<br/>
        {nome_usuario}<br/>        
        {qtd_itens}<br/>
        {vlr_itens_pedido}<br/>
        {vlr_itens_cstmed}<br/>
        {vlr_estoque_total_rep}<br/>
        {vlr_estoque_total_cstmed}<br/>
        {vlr_perc_itens_estoque_rep}<br/>
        {vlr_perc_itens_estoque_cstmed}<br/>
        {dt_ultpedido}<br/>
        {dt_ultcarga}<br/>
        {lista_itens}
    `;

    const { showAlert } = UseAlert()

    function Loading() {
        return (
            <SpinnerSC>
                <div
                    className='spinner-border text-secondary'
                    role='status'
                ></div>
            </SpinnerSC>
        );
    }

    const handleUserSelection = (userId: string | null) => {
        setSelectedUserId(userId);
        if (userId)
            getFilterAnalyticalByUser(userId);
    };

    const getFilterAnalyticalByUser = async (userId: string | null) => {
        try {
            const response = await new RestUseCase(`${ENDPOINT_ANALYTICAL}/filtros-usuario?idUsuario=${userId}`).Get();

            // Mapeie os dados para o formato desejado antes de definir o estado
            const responseVaues = response.body.map((item: any) => ({
                id: item.id,
                nome: item.nome
            }));

            setFilterData(responseVaues);
        } catch (error) {
            console.error('Erro ao buscar os dados analíticos:', error);
        }
    }  

    const handleChangeMessage = (event: any) => {
        setMessage(event);
    };

    const handleChangeModelo = (event: React.ChangeEvent<HTMLSelectElement>) => {
        let selectedValue = 0;

        if (event.target.value != null)
            selectedValue = parseInt(event.target.value, 10);

        setSelectedMensagemId(selectedValue);
        const selectedItem = planejamentoAutoData.find(item => item.id === selectedValue);
        setMessage(selectedItem?.mensagem ?? '');
    };

    const handleChangeAnalise = (event: React.ChangeEvent<HTMLSelectElement>) => {
        let selectedValue = 0;

        if (event.target.value != null)
            selectedValue = parseInt(event.target.value, 10);

        setIdAnalise(selectedValue);
    };

    const handleChangeNome = (event: any) => {
        setNome(event.target.value);
    };

    const handleChangeEmails = (event: any) => {
        setEmails(event.target.value);
    };

    const handleChangeIntervaloDias = (event: any) => {
        setIntervaloDias(parseInt(event.target.value));
    };

    const handleChangeQuantidadeDiasUltimaCarga = (event: any) => {
        setQuantidadeDiasUltimaCarga(parseInt(event.target.value));
    };

    const handleChangePercentualMinimoEstoqueCustoMedio = (event: any) => {
        setPercentualMinimoEstoqueCustoMedio(parseInt(event.target.value));
    };

    const handleChangePercentualMinimoEstoqueReposicao = (event: any) => {
        setPercentualMinimoEstoqueReposicao(parseInt(event.target.value));
    };

    const handleChangeValorMinimoCustoMedio = (event: any) => {
        setValorMinimoCustoMedio(parseInt(event.target.value));
    };

    const handleChangeValorMinimoReposicao = (event: any) => {
        setValorMinimoReposicao(parseInt(event.target.value));
    };

    const handleChangeQuantidadeMinimaItens = (event: any) => {
        setQuantidadeMinimaItens(parseInt(event.target.value));
    };

    const getAll = async (idEmpresa: any) => {
        try {
            const response = await new RestUseCase(
                `${ENDPOINT_CRUD}?idEmpresa=${idEmpresa}&page=0&size=50`
            ).Get();

            if (response.statusCode !== 500 && response.statusCode !== undefined) {
                let planejamento = response.body.map((value: any) => {
                    return { id: value.id, nome: value.nome, mensagem: value.mensagem };
                })
                setPlanejamentoAutoData(planejamento);
            }

            if (response.body) {
                const listConsultorOnline: ConsultorOnlineTab[] = response.body.map((value: ConsultorOnlineTab) => {
                    return {
                        id: value.id,
                        idAnalise: value.idAnalise,
                        intervaloDiasExecucao: value.intervaloDiasExecucao,
                        intervaloExecucao: value.intervaloExecucao,
                        mensagem: value.mensagem,
                        emails: value.emails,
                        nome: value.nome,
                        percentualMinimoEstoqueCustoMedio: value.percentualMinimoEstoqueCustoMedio,
                        percentualMinimoEstoqueReposicao: value.percentualMinimoEstoqueReposicao,
                        quantidadeDiasUltimaCarga: value.quantidadeDiasUltimaCarga,
                        quantidadeMinimaItens: value.quantidadeMinimaItens,
                        ultimaExecucao: value.ultimaExecucao,
                        valorMinimoCustoMedio: value.valorMinimoCustoMedio,
                        valorMinimoReposicao: value.valorMinimoReposicao,
                        dataAlteracao: value.dataAlteracao
                    }
                });
              
                if (listConsultorOnline.length > 0) {
                    const tableRows = listConsultorOnline.map((item: any) => (
                        <tr key={item.id} onClick={() => handleRowClick(item)} style={{height: '10px'}}>
                            <td>{item.nome}</td>
                            <td>{new Date(item.dataAlteracao).toLocaleDateString()}</td>
                            <td>{item.ultimaExecucao}</td>                            
                            <td>{item.intervaloDiasExecucao}</td>
                            <td>{item.percentualMinimoEstoqueCustoMedio}</td>
                            <td>{item.percentualMinimoEstoqueReposicao}</td>
                            <td>{item.quantidadeDiasUltimaCarga}</td>
                            <td>{item.quantidadeMinimaItens}</td>
                            <td>{item.valorMinimoCustoMedio}</td>
                            <td>{item.valorMinimoReposicao}</td>                            
                        </tr>
                    ));

                    // Definir o HTML da tabela em setTabBody
                    setTabBody(
                        <div
                            className='d-flex'
                            style={{ height: '60vh', backgroundColor: '#fff', margin: '10px 15px 10px 15px' }}
                        >
                            <TableHeaderFixedStyled>
                                <table className="table table-hover" style={{ margin: '0' }}>
                                    <thead>
                                        <tr>
                                            <th>Nome</th>
                                            <th>Data Alteração</th>
                                            <th>Última Execução</th>
                                            <th>Intervalo Dias Execução</th>
                                            <th>Percentual Min. Estoque Custo Médio</th>
                                            <th>Percentual Min. Estoque Reposição</th>
                                            <th>Qtde. de Dias da Últia Carga</th>
                                            <th>Qtde. Mínima de Itens</th>
                                            <th>Valor Min. Custo Médio</th>
                                            <th>Valor Min. Reposição</th>                                            
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableRows}
                                    </tbody>
                                </table>
                            </TableHeaderFixedStyled>
                        </div>                    
                    );

                } else {
                    setTabBody(
                        <div
                            className='d-flex align-items-center justify-content-center'
                            style={{ height: '60vh', backgroundColor: '#fff', margin: '10px 15px 10px 15px' }}
                        >
                            <span>verifique seus paramentros de busca...</span>
                        </div>
                    )
                }
            }
        } catch (error) {
            console.error('Erro ao buscar os dados:', error);
        }
    }

    const handleRowClick = (selectedItem: ConsultorOnlineTab) => {
        if (selectedItem) {
            setNome(selectedItem.nome);
            setIntervaloDias(selectedItem.intervaloDiasExecucao);
            setQuantidadeDiasUltimaCarga(selectedItem.quantidadeDiasUltimaCarga);
            setPercentualMinimoEstoqueCustoMedio(selectedItem.percentualMinimoEstoqueCustoMedio);
            setPercentualMinimoEstoqueReposicao(selectedItem.percentualMinimoEstoqueReposicao);
            setValorMinimoCustoMedio(selectedItem.valorMinimoCustoMedio);
            setValorMinimoReposicao(selectedItem.valorMinimoReposicao);
            setQuantidadeMinimaItens(selectedItem.quantidadeMinimaItens);
            setMessage(selectedItem.mensagem);
            setEmails(selectedItem.emails)
            setIdAnalise(selectedItem.idAnalise);
            setSelectedMensagemId(selectedItem.id);
            setStatusCrud('patch');
            openModal();
        }
    };
    

    const saveAll = () => {
        const model = {
            nome: nome,
            idAnalise: idAnalise,
            intervaloDiasExecucao: intervaloDias,
            quantidadeDiasUltimaCarga: quantidadeDiasUltimaCarga,
            percentualMinimoEstoqueCustoMedio: percentualMinimoEstoqueCustoMedio,
            percentualMinimoEstoqueReposicao: percentualMinimoEstoqueReposicao,
            valorMinimoCustoMedio: valorMinimoCustoMedio,
            valorMinimoReposicao: valorMinimoReposicao,
            quantidadeMinimaItens: quantidadeMinimaItens,
            mensagem: message,
            emails: emails,
            idEmpresa: selectedCompanyId
        }; 

        if(statusCrud === 'post')
            postConsultorOnline(model);

        if(statusCrud === 'patch')
            patchConsultorOnline(model);
    };

    const postConsultorOnline = async(model: any) => {
        //Verificar pra pegar o status ok, mostrar mensagem na tela e limpar tela.
        const response = await new RestUseCase(`${ENDPOINT_CRUD}`)
            .Post({ data: model })
            .finally();
        console.log("save response -> ", response);        
        
        if (response.statusCode === 201 && response.statusCode !== undefined) {
            showAlert({ show: true, content: "Cadastro realizado com sucesso", color: "success", time: 5000 });
            clearField();
            getAll(selectedCompanyId);
        }else{
            showAlert({ show: true, content: "Erro ao realizar o cadastro", color: "danger", time: 5000 });
        }
    } 

    const patchConsultorOnline = async(model: any) => {        
        const url = ENDPOINT_CRUD + "/" + selectedMensagemId;
        const response = await new RestUseCase(`${url}`)
            .Patch({ data: model })
            .finally();
        console.log("patch response -> ", response);        
        
        if (response.statusCode === 204 && response.statusCode !== undefined) {
            showAlert({ show: true, content: "Cadastro alterado com sucesso", color: "success", time: 5000 });
            clearField();
            getAll(selectedCompanyId);
        }else{
            showAlert({ show: true, content: "Erro ao atualizar o cadastro", color: "danger", time: 5000 });
        }
    } 

    function clearField(): void {
        setNome("");
        setIntervaloDias(0);
        setQuantidadeDiasUltimaCarga(0);
        setPercentualMinimoEstoqueCustoMedio(0);
        setPercentualMinimoEstoqueReposicao(0);
        setValorMinimoCustoMedio(0);
        setValorMinimoReposicao(0);
        setQuantidadeMinimaItens(0);
        setMessage("");
        setEmails("");
        setIdAnalise(0);
        setSelectedMensagemId(0);
        setStatusCrud('post');
    }

    // Função para abrir a modal
    const openModal = () => setShowModal(true);

    // Função para fechar a modal
    const closeModal = () => setShowModal(false);

    function novo(): void {        
        if(selectedCompanyId === null){
            showAlert({ show: true, content: "Selecione uma empresa", color: "warning", time: 5000 });
        }else{
            clearField(); 
            openModal();
        }        
    }

    return (
        <>
            <Headers />
            {loading && <Loading />}
            <div className='mx-3'>
                <TitleGroupComponent titlePage={titlePage}>
                    <Row></Row>
                </TitleGroupComponent>
                <SelectCompanyUsers
                    onCompanyChange={(companyId) => {
                        setSelectedCompanyId(companyId);
                        getAll(companyId);                        
                    }}
                    onUserChange={handleUserSelection}
                    onNewButtonClick={novo}
                />

            </div>

            {/* Body - Tabela */}
            {tabBody}           

            {/* Modal */}
            <Modal show={showModal} onHide={closeModal} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>{titlePage}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='mx-3'>
                        <form
                            className='mt-0'
                            noValidate
                        >
                            <Row className="mt-3">
                                <Col>Nome:</Col>
                                <Col>Planejamento:</Col>
                                <Col>Intervalo Dias Execução:</Col>
                            </Row>
                            <Row className="mt-3">
                                <Col>
                                    <Form.Control
                                        type="text"
                                        placeholder="Nome"
                                        maxLength={50}
                                        value={nome}
                                        onChange={handleChangeNome}
                                    />
                                </Col>
                                <Col>
                                    <Form.Select
                                        id="combo-analise"
                                        disabled={filterData.length === 0}
                                        value={idAnalise}
                                        onChange={handleChangeAnalise}
                                    >
                                        <option value="0">Selecione um planejamento</option>
                                        {filterData.map((item) => (
                                            <option key={item.id} value={item.id}>{item.nome}</option>
                                        ))}
                                    </Form.Select>
                                </Col>
                                <Col>
                                    <Form.Control
                                        type="number"
                                        placeholder="Intervalo Dias Execução"
                                        value={intervaloDias}
                                        onChange={handleChangeIntervaloDias}
                                        min={0}
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col>Quantidade de Dias Última Carga:</Col>
                                <Col>Percentual Mínimo de Estoque Custo Médio:</Col>
                                <Col>Percentual Mínimo Estoque Reposição:</Col>
                            </Row>
                            <Row className="mt-3">
                                <Col>
                                    <Form.Control
                                        type="number"
                                        placeholder="Quantidade de Dias Última Carga"
                                        value={quantidadeDiasUltimaCarga}
                                        onChange={handleChangeQuantidadeDiasUltimaCarga}
                                        min={0}
                                    />
                                </Col>
                                <Col>
                                    <Form.Control
                                        type="number"
                                        placeholder="Percentual Mínimo de Estoque Custo Médio"
                                        value={percentualMinimoEstoqueCustoMedio}
                                        onChange={handleChangePercentualMinimoEstoqueCustoMedio}
                                        min={0}
                                    />
                                </Col>
                                <Col>
                                    <Form.Control
                                        type="number"
                                        placeholder="Percentual Mínimo Estoque Reposição"
                                        value={percentualMinimoEstoqueReposicao}
                                        onChange={handleChangePercentualMinimoEstoqueReposicao}
                                        min={0}
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col>Valor Mínimo Custo Médio:</Col>
                                <Col>Valor Mínimo Reposição:</Col>
                                <Col>Quantidade Mínima Itens:</Col>
                            </Row>
                            <Row className="mt-3">
                                <Col>
                                    <Form.Control
                                        type="number"
                                        placeholder="Valor Mínimo Custo Médio"
                                        value={valorMinimoCustoMedio}
                                        onChange={handleChangeValorMinimoCustoMedio}
                                        min={0}
                                    />
                                </Col>
                                <Col>
                                    <Form.Control
                                        type="number"
                                        placeholder="Valor Mínimo Reposição"
                                        value={valorMinimoReposicao}
                                        onChange={handleChangeValorMinimoReposicao}
                                        min={0}
                                    />
                                </Col>
                                <Col>
                                    <Form.Control
                                        type="number"
                                        placeholder="Quantidade Mínima Itens"
                                        value={quantidadeMinimaItens}
                                        onChange={handleChangeQuantidadeMinimaItens}
                                        min={0}
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col>Emails (serparado por vírgula, ponte-e-vírgula ou espaço):</Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Control
                                        type="text"
                                        placeholder="Emails"
                                        maxLength={10000}
                                        value={emails}
                                        onChange={handleChangeEmails}
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-1">
                                <Col>Modelo:</Col>
                            </Row>
                            <Row className="mt-1">
                                <Col>
                                    <Form.Select
                                        id="combo-planejamento-auto"
                                        disabled={planejamentoAutoData.length === 0}
                                        value={selectedMensagemId}
                                        //defaultValue=""
                                        onChange={handleChangeModelo}
                                    >
                                        <option value="0" >Selecione um modelo</option>
                                        {planejamentoAutoData.map((item) => (
                                            <option key={item.id} value={item.id}>{item.nome}</option>
                                        ))}
                                    </Form.Select>
                                </Col>
                            </Row>
                            <Row className="mt-1">
                                <Col>
                                    Mensagem:
                                    <FaQuestionCircle
                                        data-tip={tooltipVariaveis}
                                        style={{ cursor: 'pointer', fontSize: '16px', color: '#007bff', marginLeft: '8px' }}
                                    />
                                    <ReactTooltip html={true} place="top" type="dark" effect="solid" />
                                </Col>
                            </Row>
                            <Row className="mt-1">
                                <Col>
                                    <JoditEditor
                                        ref={editorRef}
                                        value={message}
                                        onBlur={(event) =>
                                            handleChangeMessage(event)
                                        }
                                        // config={{
                                        //     processPasteFromWord: true,
                                        //     processPasteHTML: false,
                                        //     memorizeChoiceWhenPasteFragment: true,
                                        //     leaveOnBlur: false,
                                        //     toolbarAdaptive: false,
                                        //     buttons: [
                                        //         'source',
                                        //         '|', 'bold', 'italic',
                                        //         '|', 'ul', 'ol',
                                        //         '|', 'font', 'fontsize', 'brush', 'paragraph',
                                        //         '|', 'video', 'table', 'link',
                                        //         '|', 'left', 'center', 'right', 'justify',
                                        //         '|', 'undo', 'redo',
                                        //         '|', 'hr', 'eraser', 'fullsize',
                                        //     ],
                                        // }}
                                    />
                                </Col>
                            </Row>
                        </form>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>Fechar</Button>
                    <Button variant="primary" onClick={() => {saveAll();}}>Salvar</Button>
                    <Button variant="outline-primary" onClick={clearField}>Limpar</Button>
                </Modal.Footer>
            </Modal> 
        </>
    );
}

export default RegraConsultorOnlinePage;