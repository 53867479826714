import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { RestUseCase } from 'data/useCase/rest-usecase';
import { getEnv } from 'main/helper/window-helper';
import { SelectOptionInterface } from 'domain/entity/interface/select-option-interface';
import { Col, Row, Button } from 'react-bootstrap'; 

interface Props {
    onCompanyChange: (companyId: string | null) => void;
    onUserChange: (userId: string | null) => void;
    onNewButtonClick: () => void;
}

const SelectCompanyUsers: React.FC<Props> = ({ onCompanyChange, onUserChange, onNewButtonClick  }) => {
    const ENDPOINT_COMPANIES = getEnv('REACT_APP_END_POINT_COMPANIES')!;
    const ENDPOINT_USERS = getEnv('REACT_APP_END_POINT_USER')!;

    const [companyOptions, setCompanyOptions] = useState<SelectOptionInterface[]>([]);
    const [userOptions, setUserOptions] = useState<SelectOptionInterface[]>([]);
    const [selectedCompanyId, setSelectedCompanyId] = useState<string | null>(null);

    useEffect(() => {
        async function fetchCompanies() {
            try {
                const response = await new RestUseCase(ENDPOINT_COMPANIES).Get();
                if (response.statusCode !== 500 && response.statusCode !== undefined) {
                    const companies = response.body.map((value: any) => ({
                        value: value.id.toString(),
                        label: value.nome,
                    }));
                    setCompanyOptions(companies);
                }
            } catch (error) {
                console.error('Erro ao buscar as empresas:', error);
            }
        }

        fetchCompanies();
    }, []);

    useEffect(() => {
        async function fetchUsers() {
            if (selectedCompanyId) {
                try {
                    const response = await new RestUseCase(`${ENDPOINT_USERS}?empresa=${selectedCompanyId}`).Get();
                    if (response.statusCode !== 500 && response.statusCode !== undefined) {
                        const users = response.body.map((value: any) => ({
                            value: value.id.toString(),
                            label: value.nome,
                        }));
                        setUserOptions(users);
                    }
                } catch (error) {
                    console.error('Erro ao buscar os usuários:', error);
                }
            }
        }

        fetchUsers();
    }, [selectedCompanyId]);

    const handleCompanyChange = (selectedOption: SelectOptionInterface | null) => {
        const companyId = selectedOption ? selectedOption.value : null;
        setSelectedCompanyId(companyId);
        onCompanyChange(companyId);
    };

    const handleUserChange = async (selectedOption: SelectOptionInterface | null) => {
        const userId = selectedOption ? selectedOption.value : null;
        onUserChange(userId);
    };

    return (
        <div>
            <Row className="mt-3">
                <Col>Empresa:</Col>
                <Col>Usuário:</Col>
                <Col></Col>
            </Row>
            <Row className="mt-3">
                <Col>
                    <Select
                        options={companyOptions}
                        onChange={handleCompanyChange}
                        placeholder="Selecione uma empresa"
                        isSearchable
                    />
                </Col>
                <Col>
                    <Select
                        id="selectUser"
                        options={userOptions}
                        onChange={handleUserChange}
                        placeholder="Selecione um usuário"
                        isSearchable
                        isDisabled={!userOptions.length}
                    />
                </Col>
                <Col>
                    {/* Botão "Novo" que abre a modal */}
                    <Button
                        variant="primary"
                        onClick={onNewButtonClick} // Chama a função para abrir a modal
                    >
                        Novo
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

export default SelectCompanyUsers;
