import { useEffect, useState } from 'react';
import { UseAuth } from 'presentation/hook/auth-hook';
import ErrorNotAuthorizedPage from 'presentation/pages/error/error-not-authorized-page';
import { HeaderNavegationList } from 'main/adapter/headerNavigation/header-navegation-adapter';
import { getEnv } from 'main/helper/window-helper';
import RegraConsultorOnlinePage from 'presentation/pages/settings/regra-consultor-online-page';
import { Footer } from 'presentation/components/footer/footer-component';

export const OnlineConsultantFactoryV2: React.FC = () => {
  const [showScreen, updateShowScreen] = useState<boolean>(false);

  const ENDPOINT_CRUD = getEnv('REACT_APP_END_POINT_ONLINE_CONSULTANT')!;
  
  const titlePage = 'Cadastrar Regras de Consultor Online';
  const { loggedUserData } = UseAuth();  

  // Begin: Bloco que verificar permissões de acesso  
  useEffect(() => {
    const roleScreen = HeaderNavegationList.filter(
      (header) => header.navTitle === 'Configurações',
    )[0].navItem.filter(
      (item) => item.title === 'Cadastrar Regras de Consultor Online',
    )[0].role;

    if (loggedUserData?.role) {
      loggedUserData.role.forEach((value) => {
        if (roleScreen.includes(value)) updateShowScreen(true);
      });
    }
  }, [loggedUserData]);
  // End: Bloco que verificar permissões de acesso
  
  return (
    <>    
      {showScreen ? (
      <RegraConsultorOnlinePage titlePage={titlePage} />            
      ) : (
        <ErrorNotAuthorizedPage />
      )}
      <Footer />
    </>
  );
};
