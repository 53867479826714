import { Container } from "react-bootstrap"
import { Headers } from "presentation/components/header/headers-component"
import { UseAuth } from "presentation/hook/auth-hook"
import { useEffect, useState } from "react"
import { HeaderNavegationList } from "main/adapter/headerNavigation/header-navegation-adapter"
import { useNavigate } from "react-router-dom"

const Home: React.FC = () => {

     /** VERIFICA PERMISSAO DE ACESSO A TELA GERAR SCRAP */
     const { loggedUserData } = UseAuth()
     let navigate = useNavigate()
     useEffect(() => {
         const roleScreen = HeaderNavegationList.filter(header => header.navTitle === "Scrap")[0].navItem.filter(item => item.title === "Gerar Scrap")[0].role

         if (loggedUserData?.role) {
             loggedUserData.role.forEach(value => {
                 if (roleScreen.includes(value))
                    navigate("/")
                //todo: voltando a tela de bem vindo apos o login
             })
         }
     }, [loggedUserData])

    return (
        <>
            <Headers />
            <Container>
                <h1 className="mt-3">Bem vindo ao novo IS-COMPRAS</h1>
            </Container>
        </>
    )
}

export default Home