/* eslint-disable react-hooks/exhaustive-deps */
import { ReactNode } from "react"
import { Button, Card } from "react-bootstrap"
import { Headers } from "presentation/components/header/headers-component"
import HeaderCrud from "presentation/components/header/header-crud-component"
import { Footer } from "presentation/components/footer/footer-component"

type PropsCrud = {
    titlePage: string
    change: () => any
    children: {
        header?: ReactNode
        body: ReactNode
        footer?: ReactNode
    }
}

const ChangeUserInternalCompanyPage: React.FC<PropsCrud> = ({ titlePage, change, children }: PropsCrud) => {

    return (
        <>
            <Headers />
            <HeaderCrud title={titlePage} >
                <div>
                    <Button variant="primary" onClick={change} size="sm">Trocar</Button>
                </div>
            </HeaderCrud>
            <div className="mx-1">
                <Card>
                    <Card.Body>
                        {children.body}
                    </Card.Body>
                </Card>
            </div>
            {/* <Footer/> */}
        </>
    )

}

export default ChangeUserInternalCompanyPage