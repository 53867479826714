/* eslint-disable react-hooks/exhaustive-deps */
import Select from 'react-select';
import { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';

import { SpinnerSC } from 'presentation/style/spinner/spinner-styled';
import { SCDesctiption } from 'presentation/style/typography/titlePage';

import { Footer } from 'presentation/components/footer/footer-component';
import { Headers } from 'presentation/components/header/headers-component';
import { FiltersDefault } from 'domain/interfaces/filter-interface';
import { BsTrash } from 'react-icons/bs';
import { FilterScrapEntity } from 'domain/entity/filter-scrap-entity';
import { SelectOptionInterface } from 'domain/entity/interface/select-option-interface';
import { SelectProvider } from 'presentation/components/select/provider-select-component';
import { TitlePageComponent } from '../../components/title/title-page-component';
import { TitleGroupComponent } from '../../components/title/title-group-component';

interface CreateField {
  field: any,
  indexSelected: number
}

type Props = {
  listAllPlanning: (params?: any) => Promise<any>,
  createPlanning: (params?: any) => Promise<any>,
  optionsFiltersDefault: FiltersDefault[]
}

const titlePage = 'Filtro Scrap';

const FilterScrapPage: React.FC<Props> = ({ optionsFiltersDefault, listAllPlanning, createPlanning }: Props) => {

  /**
   *
   * CRUD MODEL / MODELS
   *
   */
  const [modelItemFilter, updateItemFilter] = useState<FilterScrapEntity[]>([]);

  async function handleModel(idProvider: number): Promise<void> {

    updateLoading(true);
    await listAllPlanning({ idProvider: idProvider })
      .then(response => {
        if (response.statusCode === 200) {
          updateItemFilter([...response.body, { type: '', params: {} }]);
        } else {
          clearFilter();
        }
      })
      .catch(error => {
        console.log('error', error);
      });

    updateLoading(false);
  }

  async function handleSave(modelFields: FilterScrapEntity[]): Promise<void> {
    let models: FilterScrapEntity[] = modelFields.filter(value => value.type !== '');

    await createPlanning({ idProvider: idProvider, modelFields: models });
    await handleModel(idProvider!);
  }

  /**
   *
   * FIELD
   *
   */
  const add = (item: FiltersDefault, indexSelected: number) => {

    if (modelItemFilter.length === 1)
      updateItemFilter([
        ...modelItemFilter.map((value, index) => {
          if (index === indexSelected)
            value.type = item.typeFilter;
          return value;
        }),
        { type: '', params: {} },
      ]);
    else
      updateItemFilter(
        modelItemFilter.map((value, index) => {
          if (index === indexSelected)
            value.type = item.typeFilter;
          return value;
        }),
      );

    let emptyQuantity: number = modelItemFilter.filter(value => value.type === '').length;

    if (emptyQuantity === 0)
      addBlank();
  };

  const addBlank = () => {
    updateItemFilter([...modelItemFilter, { type: '', params: {} }]);
  };

  const removeItem = (params: any) => {
    updateItemFilter(modelItemFilter.filter((value, index) => {
      if (index !== params) {
        return value;
      }
    }));
  };

  const clearFilter = () => {
    updateItemFilter([...[], { type: '', params: {} }]);
  };


  /**
   *
   * LOADING
   *
   */
  const [loading, updateLoading] = useState<boolean>(false);

  function Loading() {
    return (
      <SpinnerSC>
        <div className='spinner-border text-secondary' role='status'></div>
      </SpinnerSC>
    );
  }

  function getFilterByType(type: string) {
    let filtered = optionsFiltersDefault.filter(value => value.typeFilter === type);
    return filtered.length > 0 ? filtered : [];
  }

  const updateParameterValue = (index: number, param: string, value: any) => {
    updateItemFilter(
      modelItemFilter.map((item, itemIndex) => {
        if (itemIndex === index) {
          item.params[param!] = value;
        }
        return item;
      }),
    );
  };

  function FieldCreated(params: CreateField) {

    let filter: any[] = getFilterByType(params.field.type);
    const elements: React.ReactElement[] = [];

    if (filter.length > 0) {
      filter[0].field.forEach((value: any, index: number) => {
        if (value.typeInput === 'select' || value.typeInput === 'checkbox') {
          elements.push(
            <Col md={4} key={index}>
              <Select
                options={value.optionsInput}
                onChange={(e: any) => {

                  if (filter[0].label.toLowerCase() === 'pontuação do item') {
                    if (e.value === 'GREATER' || e.value === 'LESSER') {
                      if (params.field.params.pontuacoes !== undefined) {
                        params.field.params.pontuacoes = [params.field.params.pontuacoes[0]];
                      }
                    }
                  }

                  updateItemFilter(
                    modelItemFilter.map((vl: any, idx) => {
                      if (params.indexSelected === idx)
                        vl.params[value.name!] = e.value;
                      return vl;
                    }),
                  );
                }}
                defaultValue={
                  {
                    label: value.optionsInput.filter((vlFilter: any) => modelItemFilter.filter((vl: any, idx) => {
                      if (params.indexSelected === idx) return vl;
                    })[0].params[value.name!] === vlFilter.value)[0] ?
                      value.optionsInput.filter((vlFilter: any) => modelItemFilter.filter((vl: any, idx) => {
                        if (params.indexSelected === idx) return vl;
                      })[0].params[value.name!] === vlFilter.value)[0].label :
                      '',
                    value: modelItemFilter.filter((vl: any, idx) => {
                      if (params.indexSelected === idx)
                        return vl;
                    })[0].params[value.name!],
                  }
                }
              />
            </Col>,
          );
        }

        if (value.typeInput === 'multi-select') {
          const currentValue = modelItemFilter[params.indexSelected].params[value.name];

          let itemsValue;

          if (currentValue) {
            itemsValue = filter[0].field[index].optionsInput?.filter((item: any) => currentValue.includes(item?.value));
          }

          elements.push(
            <Col md={4} key={index}>
              {itemsValue === undefined
                ?
                <Select
                  options={value.optionsInput}
                  isMulti={true}
                  onChange={(e: any) => {
                    if (filter[0]?.label.toLowerCase() === 'pontuação do item') {
                      if (e.length > 1 && (params.field.params.type === 'GREATER' || params.field.params.type === 'LESSER' || params.field.params.type === 'NOT_EQUALS')) {
                        e = [e[e.length - 1]];
                      }
                    }
                    updateParameterValue(params.indexSelected, value.name, e.map((elements: any) => elements.value));
                  }}

                />
                :
                <Select
                  options={value.optionsInput}
                  isMulti={true}
                  onChange={(e: any) => {
                    if (filter[0]?.label.toLowerCase() === 'pontuação do item') {
                      if (e.length > 1 && (params.field.params.type === 'GREATER' || params.field.params.type === 'LESSER' || params.field.params.type === 'NOT_EQUALS')) {
                        e = [e[e.length - 1]];
                      }
                    }

                    updateParameterValue(params.indexSelected, value.name, e.map((elements: any) => elements.value));
                  }}
                  defaultValue={() => {
                    const currentValue = modelItemFilter[params.indexSelected]?.params[value.name] ?? [];
                    return value.optionsInput.filter(
                      (opt: {
                        label: string,
                        value: any
                      }) => currentValue.includes(opt?.value));
                  }}
                />
              }

            </Col>,
          );
        }

        if (value.typeInput === 'text' || value.typeInput === 'date') {
          elements.push(
            <Col md={4} key={index}>
              <Form.Control
                type={value.typeInput}
                onBlur={(e) => {
                  e.preventDefault();
                  updateItemFilter(
                    modelItemFilter.map((vl: any, idx) => {
                      if (params.indexSelected === idx)
                        vl.params[value.name!] = e.target.value;
                      return vl;
                    }),
                  );
                }}
                defaultValue={
                  modelItemFilter.filter((vl: any, idx) => {
                    if (params.indexSelected === idx)
                      return vl;
                  })[0].params[value.name!]
                }
              />
            </Col>,
          );
        }
        //todo: colocando text-list, replicando como esta no planejamento
        if (value.typeInput === 'text-list') {
          elements.push(
            <Col md={4} key={index}>
              <Form.Control
                type={value.typeInput}
                onBlur={(e: any) => {
                  let list = e.target.value.split(value.separator).map((item: string) => item.split(/\s+/).join(''));
                  updateParameterValue(params.indexSelected, value.name, list);
                }
                }
                defaultValue={modelItemFilter[params.indexSelected].params[value.name!]?.join(value.separator) ?? ''}
              />
            </Col>,
          );
        }

        if (value.typeInput === 'number') {
          elements.push(
            <Col md={4} key={index}>
              <Form.Control
                type={value.typeInput}
                onBlur={(e) => updateParameterValue(params.indexSelected, value.name, parseFloat(e.target.value))}
                defaultValue={modelItemFilter[params.indexSelected]?.params[value.name] ?? 0}
              />
            </Col>,
          );
        }
      });
    }

    return (
      <Row className='d-flex justify-content-start'>
        {elements}
      </Row>
    );
  }

  /**
   *
   * PROVIDER
   *
   */
  const [idProvider, updateIdProvider] = useState<number>();
  const [modelProvider, updateModelProvider] = useState<SelectOptionInterface>();

  function handleSelectProvider(filial: {
    value: number,
    label: string
  }) {
    updateModelProvider(filial);
    updateIdProvider(filial.value);
    handleModel(filial.value);
  }

  function CreateSelectProvider() {
    return (
      <>
        <Container>
          <Row className='mt-3 d-flex align-items-center justify-content-start'>
            <Col md={1}>
              <Form.Label>Fornecedor:</Form.Label>
            </Col>
            <Col md={3}>
              <SelectProvider passOnTheValue={handleSelectProvider} defaultValue={modelProvider} />
            </Col>
          </Row>
        </Container>
      </>
    );
  }

  /**
   *
   * INIT
   *
   */
  useEffect(() => {
  }, []);


  /**
   *
   * RENDER
   *
   */
  return (
    <>
      <Headers />

      {loading && <Loading />}

      <div className='mx-3'>
        <div className='mx-5'>
          <TitleGroupComponent titlePage={titlePage}>
            <Row>
              <Col className='d-flex align-items-center justify-content-end'>
                <Button variant='primary' onClick={() => handleSave(modelItemFilter)} size='sm'>Salvar</Button>
              </Col>
            </Row>
          </TitleGroupComponent>
        </div>
        <Card>
          <Card.Body>

            <Container>
              <CreateSelectProvider />
            </Container>

            {idProvider !== undefined ?
              <Container className='mt-4'>
                {modelItemFilter.map((value, index) => {

                  var optionsFiltered = optionsFiltersDefault.map(vl => {
                    return { value: vl.label, label: vl.label };
                  });
                  var optionsSelected = optionsFiltersDefault.filter(vl => vl.typeFilter === value.type);

                  var valueSelect = { value: '', label: 'Selecione um novo filtro' };

                  if (value.type !== '') {
                    valueSelect = { value: optionsSelected[0].label, label: optionsSelected[0].label };
                  }

                  return (
                    <Container className='mt-1' key={index}>
                      <Row>
                        <Col md={3}>
                          <Col>
                            <Select
                              key={index}
                              options={optionsFiltered}
                              onChange={val => {
                                add(optionsFiltersDefault.filter(value => value.label === val?.label)[0], index);
                              }}
                              value={valueSelect}
                            />
                          </Col>
                        </Col>
                        <Col>
                          <FieldCreated field={value} indexSelected={index} />
                        </Col>
                        {value.type !== '' &&
                          <Col md={1}>
                            <Button variant='danger' className='ms-1'
                                    onClick={() => removeItem(index)}><BsTrash /></Button>
                          </Col>
                        }
                      </Row>
                    </Container>
                  );
                })}
              </Container>
              :
              <></>
            }


          </Card.Body>
        </Card>
      </div>
      <Footer />
    </>
  );
};

export default FilterScrapPage;
