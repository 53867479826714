import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Home from 'presentation/pages/home';

import { AuthProvider } from 'data/context/auth-context';
import { AlertProvider } from 'data/context/alert-context';
import { AlertModalProvider } from 'data/context/alert-modal-context';

import { DefaultFactory } from 'main/factory/pages/default-factory';
import { SectorFactory } from 'main/factory/pages/provider/sector-factory';
import { MessageFactory } from 'main/factory/pages/settings/message-factory';
import { HolidayFactory } from 'main/factory/pages/settings/holiday-factory';
import { ProviderFactory } from 'main/factory/pages/settings/provider-factory';
import { RegionalFactory } from 'main/factory/pages/provider/regional-factory';
import { FileLoadItemFactory } from 'main/factory/pages/file-load-item-factory';
import { AcessLogFactory } from 'main/factory/pages/settings/acess-log-factory';
import { ItemNoteFactory } from 'main/factory/pages/settings/item-note-factory';
import { FileLoadFactory } from 'main/factory/pages/settings/file-load-factory';
import { SyntheticFactory } from 'main/factory/pages/analysis/synthetic-factory';
import { IndicatorFactory } from 'main/factory/pages/analysis/indicator-factory';
import { QueryScrapFactory } from 'main/factory/pages/scrap/query-scrap-factory';
import { CurveTurnFactory } from 'main/factory/pages/settings/curve-turn-factory';
import { ChangeUserFactory } from 'main/factory/pages/safety/change-user-factory';
import { ReportScrapFactory } from 'main/factory/pages/scrap/report-scrap-factory';
import { FilterScrapFactory } from 'main/factory/pages/scrap/filter-scrap-factory';
import { ManagerialFactory } from 'main/factory/pages/analysis/managerial-factory';
import { AnalyticalFactory } from 'main/factory/pages/analysis/analytical-factory';
import { CommuniqueFactory } from 'main/factory/pages/settings/communique-factory';
import { CurveValueFactory } from 'main/factory/pages/settings/curve-value-factory';
import { CurveScoreFactory } from 'main/factory/pages/settings/curve-score-factory';
import { ManufacturerFactory } from 'main/factory/pages/settings/manufacturer-factory';
import { EqualizationFactory } from 'main/factory/pages/analysis/equalization-factory';
import { GenerateScrapFactory } from 'main/factory/pages/scrap/generate-scrap-factory';
import { ApprovalScrapFactory } from 'main/factory/pages/scrap/approval-scrap-factory';
import { ConfigurationFactory } from 'main/factory/pages/settings/configuration-factory';
import { OriginalItemsFactory } from 'main/factory/pages/provider/original-items-factory';
import { CorrelateItemFactory } from 'main/factory/pages/provider/correlate-item-factory';
import { FilialProviderFactory } from 'main/factory/pages/provider/filial-provider-factory';
// import { OnlineConsultantFactory } from 'main/factory/pages/settings/online-consultant-factory';
import { OnlineConsultantFactoryV2 } from 'main/factory/pages/settings/online-consultant-factory-v2';
import ErrorAuthenticationTokenPage from 'presentation/pages/error/error-authentication-token-page';
import { OriginalItemStatusFactory } from 'main/factory/pages/provider/original-item-status-factory';
import { QueryItemNetworkFactory } from 'main/factory/pages/scrap/query-item-network-factory';
import { ResetPasswordFactory } from 'main/factory/pages/safety/reset-password-factory';
import { UserFactoryV2 } from 'main/factory/pages/safety/user-factory-v2';
import { CurveTurnFactoryV2 } from 'main/factory/pages/settings/curve-turn-factory-v2';
import { CompanyGroupFactory } from 'main/factory/pages/safety/company-group-factory';
import { ChangeUserInternalFactory } from 'main/factory/pages/safety/change-user-internal-company-factory';
import { InfoFactory } from '../factory/pages/settings/info-factory';
import { OrdinationFactory } from 'main/factory/pages/settings/ordination-factory';

export function Private() {
  return (
    <AuthProvider>
      <AlertProvider>
        <AlertModalProvider>
          <BrowserRouter>
            <Routes>
              <Route path="*" element={<Home />} />

              <Route path="/" element={<Home />} />
              <Route path="/usuario" element={<UserFactoryV2 />} />
              <Route path="/setor" element={<SectorFactory />} />
              <Route path="/feriado" element={<HolidayFactory />} />
              <Route path="/mensagem" element={<MessageFactory />} />
              <Route path="/regional" element={<RegionalFactory />} />
              <Route path="/log-carga" element={<AcessLogFactory />} />
              <Route path="/file-load" element={<FileLoadFactory />} />
              <Route path="/sintetico" element={<SyntheticFactory />} />
              <Route path="/indicador" element={<IndicatorFactory />} />
              <Route path="/cadastrar/fornecedor" element={<ProviderFactory />} />
              <Route path="/analitico" element={<AnalyticalFactory />} />
              <Route path="/gerencial" element={<ManagerialFactory />} />
              <Route path="/giro-curva-empresa" element={<CurveTurnFactory />} />
              <Route path="/giro-curva-usuario" element={<CurveTurnFactoryV2 />} />
              <Route path="/pagina-padrao" element={<DefaultFactory />} />
              <Route path="/valor-curva" element={<CurveValueFactory />} />
              <Route path="/comunicacao" element={<CommuniqueFactory />} />
              <Route path="/fabricante" element={<ManufacturerFactory />} />
              <Route path="/filtro-scrap" element={<FilterScrapFactory />} />
              <Route path="/equalizacao" element={<EqualizationFactory />} />
              <Route path="/troca-usuario" element={<ChangeUserFactory />} />
              <Route path="/observacao-item" element={<ItemNoteFactory />} />
              <Route path="/gerar-scrap" element={<GenerateScrapFactory />} />
              <Route path="/consulta-scrap" element={<QueryScrapFactory />} />
              <Route path="/consulta-item-rede" element={<QueryItemNetworkFactory />} />
              <Route path="/pontuacao-curva" element={<CurveScoreFactory />} />
              <Route path="/cadastrar/configuracao" element={<ConfigurationFactory />} />
              <Route path="/relatorio-scrap" element={<ReportScrapFactory />} />
              <Route path="/itens-correlato" element={<CorrelateItemFactory />} />
              <Route path="/aprovacao-scrap" element={<ApprovalScrapFactory />} />
              <Route path="/itens-originais" element={<OriginalItemsFactory />} />
              <Route path="/filial-fornecedor" element={<FilialProviderFactory />} />
              {/* <Route path="/config-consultor-online" element={<OnlineConsultantFactory />} /> */}
              <Route path="/config-consultor-online-v2" element={<OnlineConsultantFactoryV2 />} />
              <Route path="/carregar-arquivos-fornecedor" element={<FileLoadItemFactory />} />
              <Route path="/itens-originais-status" element={<OriginalItemStatusFactory />} />
              <Route path="/reset-password" element={<ResetPasswordFactory />} />
              <Route path="/grupo-empresa" element={<CompanyGroupFactory />} />
              <Route path="/troca-usuario-interno-empresa" element={<ChangeUserInternalFactory />} />
              <Route path="/tutorial" element={<InfoFactory />} />
              <Route path="/ordenacao" element={<OrdinationFactory />} />

              <Route path="/error-token" element={<ErrorAuthenticationTokenPage />} />
            </Routes>
          </BrowserRouter>
        </AlertModalProvider>
      </AlertProvider>
    </AuthProvider>
  );
}

export default Private;
