/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import ReactTooltip from 'react-tooltip';
import { ReactNode, useEffect, useState } from 'react';
import {
  Badge,
  Button,
  Card,
  Col,
  DropdownButton,
  Form,
  Modal,
  Pagination,
  Row,
  Table,
  Tooltip,
} from 'react-bootstrap';

import { SpinnerSC } from 'presentation/style/spinner/spinner-styled';
import { SCDesctiption } from 'presentation/style/typography/titlePage';
import { TableHeaderStyled } from 'presentation/style/table/table-header';
import { TableHeaderFixedStyled } from 'presentation/style/table/table-header-fixed-styled';

import { Footer } from 'presentation/components/footer/footer-component';
import { Headers } from 'presentation/components/header/headers-component';
import { SelectFilial } from 'presentation/components/select/filial-select-component';
import ContainerFilter from 'presentation/components/container/container-filter-component';
import {
  DragInDropHideOrderColumsTableComponent,
} from 'presentation/components/dragDrop/drag-in-drop-hide-order-colums-table-component';

import { generateRandomId } from 'main/helper/randon-helper';
import { ColumsTableSyntheticAdapter } from 'main/adapter/columsTable/colums-table-synthetic-adapter';

import { UseAlert } from 'presentation/hook/alert-hook';

import { FiltersDefault } from 'domain/interfaces/filter-interface';
import { TableDescriptions } from 'domain/interfaces/table-interface';

import {
  getLocalStorage,
  updateLocalStorege,
} from 'data/cache/localstorage-cache';

import {
  getParameterUrlByName,
  parseLinkHeader,
} from 'main/helper/request-helper';
import {
  ParseDateToBrWithHours,
  ParseDateToEn,
} from 'main/helper/format-date-helper';

import { AiFillLock } from 'react-icons/ai';

import { HiBell } from 'react-icons/hi';
import { BiColumns } from 'react-icons/bi';
import { TbSitemap } from 'react-icons/tb';
import { FiAlertTriangle } from 'react-icons/fi';
import { MdAttachMoney, MdMapsHomeWork } from 'react-icons/md';
import { GrCircleInformation, GrStatusCritical } from 'react-icons/gr';
import { BsCheckLg, BsFillCartFill, BsPercent, BsSliders, BsTag, BsXLg } from 'react-icons/bs';
import { CartesianGrid, Legend, Line, LineChart, XAxis, YAxis } from 'recharts';
import { FaSort, FaSortDown, FaSortUp, FaArrowRight, FaArrowLeft } from 'react-icons/fa';
import { Painel } from 'presentation/components/painel/peinel-component';
import { getEnv } from 'main/helper/window-helper';
import { Dropdown } from 'react-bootstrap';
import { TitlePageComponent } from '../../components/title/title-page-component';
import { TitleGroupComponent } from '../../components/title/title-group-component';

interface LinkHeader {
  first: string;
  last: string;
  next: string;
  prev: string;
}

type Props = {
  optionsFiltersDefault: FiltersDefault[];

  loggedUserDataRole: string[];
  processAndList: (params?: any) => Promise<any>;
  list: (params?: any) => Promise<any>;
  generateOrder: (params?: any) => Promise<any>;
  save: (params?: any) => Promise<any>;
  saveAll: (params?: any) => Promise<any>;
  remove: (params?: any) => Promise<any>;

  listByIdPlanning: (params?: any) => Promise<any>;
  listAllPlanning: (params?: any) => Promise<any>;
  createPlanning: (params?: any) => Promise<any>;
  editPlanning: (params?: any) => Promise<any>;
  removePlanning: (params?: any) => Promise<any>;
  exportFileCSV: (params?: any) => Promise<any>;
  exportFileExcel: (params?: any) => Promise<any>;
  graphicHistory: (params?: any) => Promise<any>;
  observationItem: (params?: any) => Promise<any>;
  movement: (params?: any) => Promise<any>;
  correlate: (params?: any) => Promise<any>;

  panelList: any[];
  lastCharge: string;
  typeRemanejamento: {
    dataPesquisa: string;
    tipo: string;
  };
};

const titlePage = 'Sintético';
const LOCALSTORAGE_COLUMS = getEnv('REACT_APP_LOCALSTORAGE_COLUMS_SYNTHETIC')!;
const LOCALSTORAGE_COLUMS_ORDER = getEnv('REACT_APP_LOCALSTORAGE_COLUMS_SYNTHETIC')! + '_order';
const LOCALSTORAGE_PLANNING = getEnv('REACT_APP_LOCALSTORAGE_PLANNING_SYNTHETIC')!;

const SyntheticPage: React.FC<Props> = ({
                                          optionsFiltersDefault,
                                          processAndList,
                                          list,
                                          generateOrder,
                                          save,
                                          saveAll,
                                          remove,
                                          listAllPlanning,
                                          createPlanning,
                                          editPlanning,
                                          removePlanning,
                                          exportFileCSV,
                                          exportFileExcel,
                                          graphicHistory,
                                          observationItem,
                                          movement,
                                          correlate,
                                          loggedUserDataRole,
                                          panelList,
                                          lastCharge,
                                          typeRemanejamento,
                                        }: Props) => {
  /**
   *
   *
   *
   *
   *  ultima carga / pesquisa */
  const [lastSearch, updateLastSearch] = useState<string>();

  /**
   *
   *
   *
   *
   *  loading */
  const [loading, updateLoading] = useState<boolean>(false);

  function Loading() {
    return (
        <SpinnerSC>
          <div
              className='spinner-border text-secondary'
              role='status'
          ></div>
        </SpinnerSC>
    );
  }

  /**
   *
   *
   *
   *
   *  alerta */
  const { showAlert } = UseAlert();

  /**
   *
   *
   *
   *
   *  paginacao */
  var totalPageSize: number = 50;
  const [firstPagination, updateFirstPagination] = useState<string>('');
  const [lastPagination, updateLastPagination] = useState<string>('');
  const [nextPagination, updateNextPagination] = useState<string>('');
  const [prevPagination, updatePrevPagination] = useState<string>('');
  const [paginationItemList, updatePaginationItemList] =
      useState<{
        item: number;
        active: boolean
      }[]>();
  const setValuePaginations = (
      paginationsActive: number,
      prev: number,
      next: number,
  ) => {
    let modelPaginationList: any[] = [];
    for (
        let index = paginationsActive;
        index < 6 + paginationsActive;
        index++
    ) {
      if (!(index > next))
        modelPaginationList.push({
          item: index,
          active: index === paginationsActive ? true : false,
        });
    }
    updatePaginationItemList(modelPaginationList);
  };
  const onClickItemPagination = async (pagination: any) => {
    totalPageSize = Number(getParameterUrlByName('size', nextPagination))!;
    await handleTable({
      pagination: `?page=${pagination.item}&size=${totalPageSize}`,
      page: parseInt(pagination.item),
      size: totalPageSize,
    });
  };
  const onClickFirstPagination = async () => {
    const size: string = getParameterUrlByName('size', firstPagination)!;
    await handleTable({
      pagination: `?page=${0}&size=${size}`,
      page: 0,
      size: parseInt(size),
    });
  };
  const onClickLastPagination = async () => {
    const page: string = getParameterUrlByName('page', lastPagination)!;
    const size: string = getParameterUrlByName('size', lastPagination)!;
    await handleTable({
      pagination: `?page=${page}&size=${size}`,
      page: parseInt(page),
      size: parseInt(size),
    });
  };
  const onClickNextPagination = async () => {
    const page: string = getParameterUrlByName('page', nextPagination)!;
    const size: string = getParameterUrlByName('size', nextPagination)!;
    await handleTable({
      pagination: `?page=${page}&size=${size}`,
      page: parseInt(page),
      size: parseInt(size),
    });
  };
  const onClickPrevPagination = async () => {
    const page: string = getParameterUrlByName('page', prevPagination)!;
    const size: string = getParameterUrlByName('size', prevPagination)!;
    await handleTable({
      pagination: `?page=${page}&size=${size}`,
      page: parseInt(page),
      size: parseInt(size),
    });
  };

  /**
   *
   *
   *
   *
   *  model table */
  const [listTableModel, updateListTableModel] = useState<any[]>([]);

  /**
   *
   *
   *
   *
   *  chamadas so banckend */
  async function handleTable(params?: {
    pagination: string;
    page: number;
    size: number;
    ordination?: string;
  }) {
    let pagePagination;
    /** inicia o loading */
    updateLoading(true);
    let response;
    /** configura paginacao manual ou vindo de outros metodos */
    if (params) {
      response = await list({ pagination: params.pagination });
      pagePagination = params.page;
    } else {
      pagePagination = 0;
      response = await list({
        pagination: `?page=${pagePagination}&size=${totalPageSize}`,
      });
    }
    /** links da paginacao */
    if (response.headers['link']) {
      let links: LinkHeader = parseLinkHeader(
          response.headers['link'],
      ) as any;

      updateFirstPagination(links.first);
      updateLastPagination(links.last);
      updateNextPagination(links.next);
      updatePrevPagination(links.prev);

      setValuePaginations(
          pagePagination,
          parseInt(getParameterUrlByName('page', links.first)!),
          parseInt(getParameterUrlByName('page', links.last)!),
      );
    }
    /** data da ultima carga */
    if (response.headers['is-ultima-pesquisa'])
      updateLastSearch(
          response.headers['is-ultima-pesquisa'] === 'null'
              ? 'NULL'
              : ParseDateToBrWithHours(
                  response.headers['is-ultima-pesquisa'],
              ),
      );
    /** dados da requisicao */
    if (response.statusCode === 200) {
      updateListTableModel(
          response.body.map((value: any) => {
            /** trata os dados */
            if (value['dataPesquisa'])
              value['dataPesquisa'] = ParseDateToEn(
                  value['dataPesquisa'],
              );

            return value;
          }),
      );
      changeValueListByColums(response.body);
    } else
      showAlert({
        show: true,
        content: 'Ops!!! Algo deu errado tente novamente mais tarde...',
        color: 'danger',
        time: 2000,
      });
    /** finaliza o loading */
    updateLoading(false);
  }

  async function handleProcessTable() {
    showAlert({
      show: true,
      content: 'Aguarde um momento',
      color: 'warning',
      time: 2000,
    });
    closeProcess();
    /** inicia o loading */
    updateLoading(true);

    let response = await processAndList({
      pagination: `?page=${0}&size=${totalPageSize}`,
      model: modelFilial,
    });
    /** dados da requisicao */
    if (response.statusCode !== 500) {
      updateListTableModel(
          response.body.map((value: any) => {
            /** trata os dados */
            if (value['dataPesquisa'])
              value['dataPesquisa'] = ParseDateToEn(
                  value['dataPesquisa'],
              );

            return value;
          }),
      );
      showAlert({
        show: true,
        content: 'Processado com sucesso',
        color: 'success',
        time: 2000,
      });
      /** data da ultima carga */
      if (response.headers['is-ultima-pesquisa'])
        updateLastSearch(
            response.headers['is-ultima-pesquisa'] === 'null'
                ? 'NULL'
                : ParseDateToBrWithHours(
                    response.headers['is-ultima-pesquisa'],
                ),
        );
      /** links da paginacao */
      if (response.headers['link']) {
        let links: LinkHeader = parseLinkHeader(
            response.headers['link'],
        ) as any;

        updateFirstPagination(links.first);
        updateLastPagination(links.last);
        updateNextPagination(links.next);
        updatePrevPagination(links.prev);

        setValuePaginations(
            0,
            parseInt(getParameterUrlByName('page', links.first)!),
            parseInt(getParameterUrlByName('page', links.last)!),
        );
      }
    } else
      showAlert({
        show: true,
        content: 'Ops!!! Algo deu errado tente novamente mais tarde...',
        color: 'danger',
        time: 2000,
      });
    /** finaliza o loading */
    updateLoading(false);
  }

  async function handleDeletePage() {
    showAlert({
      show: true,
      content: 'Aguarde um momento',
      color: 'warning',
      time: 2000,
    });
    let model: any[] = [];

    listTable.forEach((value) => {
      model.push({
        filial: value['filial'],
        id: value['id'],
        // quantidade: parseInt(value["quantidade"]),
      });
    });

    let response = await remove(model);
    /** dados da requisicao */
    if (response.statusCode !== 500)
      showAlert({
        show: true,
        content: 'Página removida com sucesso',
        color: 'success',
        time: 2000,
      });
    else
      showAlert({
        show: true,
        content: 'Ops!!! Algo deu errado tente novamente mais tarde...',
        color: 'danger',
        time: 2000,
      });
    /** chama a listagem da tela */
    await handleTable();
  }

  async function handleDelete(item: any) {
    showAlert({
      show: true,
      content: 'Aguarde um momento',
      color: 'warning',
      time: 2000,
    });
    let response = await remove([{ filial: item.filial, id: item.id }]);
    /** dados da requisicao */
    if (response.statusCode !== 500) {
      updateListTableModel(
          listTableModel.map((value: any) => {
            if (value['dataPesquisa'])
              value['dataPesquisa'] = ParseDateToEn(
                  value['dataPesquisa'],
              );

            if (value.id === item.id) value['alterado'] = true;

            return value;
          }),
      );
      showAlert({
        show: true,
        content: 'Item removido com sucesso',
        color: 'success',
        time: 2000,
      });
    } else {
      showAlert({
        show: true,
        content: 'Ops!!! Algo deu errado tente novamente mais tarde...',
        color: 'danger',
        time: 2000,
      });
    }
    /** chama a listagem da tela */
    await handleTable();
  }

  async function handleSavePage() {
    showAlert({
      show: true,
      content: 'Aguarde um momento',
      color: 'warning',
      time: 2000,
    });
    let model: any[] = [];

    listTable.forEach((value) => {
      model.push({
        filial: value['filial'],
        id: value['id'],
        quantidade: parseInt(value['quantidade']),
      });
    });
    let response = await save(model);
    /** dados da requisicao */
    if (response.statusCode !== 500)
      showAlert({
        show: true,
        content: 'Página Salva com sucesso',
        color: 'success',
        time: 2000,
      });
    else
      showAlert({
        show: true,
        content: 'Ops!!! Algo deu errado tente novamente mais tarde...',
        color: 'danger',
        time: 2000,
      });
    /** chama a listagem da tela */
    await handleTable();
  }

  async function handleSaveAlteration() {
    showAlert({
      show: true,
      content: 'Aguarde um momento',
      color: 'warning',
      time: 2000,
    });
    let model: any[] = [];

    let response = await save(listUpdateAll);

    /** dados da requisicao */
    if (response.statusCode !== 500)
      showAlert({
        show: true,
        content: 'Alteração realizada com sucesso',
        color: 'success',
        time: 2000,
      });
    else
      showAlert({
        show: true,
        content: 'Ops!!! Algo deu errado tente novamente mais tarde...',
        color: 'danger',
        time: 2000,
      });
    /** chama a listagem da tela */
    await handleTable();
    updateAllTable([]);
  }

  async function handleSaveAll() {
    showAlert({
      show: true,
      content: 'Aguarde um momento',
      color: 'warning',
      time: 2000,
    });
    /** inicia o loading */
    updateLoading(true);

    let response = await saveAll();

    /** dados da requisicao */
    if (response.statusCode !== 500)
      showAlert({
        show: true,
        content: 'Tudo Salvo com sucesso',
        color: 'success',
        time: 2000,
      });
    else
      showAlert({
        show: true,
        content: 'Ops!!! Algo deu errado tente novamente mais tarde...',
        color: 'danger',
        time: 2000,
      });
    /** chama a listagem da tela */
    await handleTable();
    /** finaliza o loading */
    updateLoading(false);
  }

  async function handleSaveItem(item: any) {
    showAlert({
      show: true,
      content: 'Aguarde um momento',
      color: 'warning',
      time: 2000,
    });
    let response = await save([
      { filial: item.filial, id: item.id, quantidade: item.quantidade },
    ]);
    /** dados da requisicao */
    if (response.statusCode !== 500)
      showAlert({
        show: true,
        content: 'Item salvo com sucesso',
        color: 'success',
        time: 2000,
      });
    else
      showAlert({
        show: true,
        content: 'Ops!!! Algo deu errado tente novamente mais tarde...',
        color: 'danger',
        time: 2000,
      });
    /** chama a listagem da tela */
    await handleTable();
  }

  /**
   *
   *
   *
   *
   *  exporta arquivos CSV/EXCEL */
  const [showModalExport, updateShowModalExport] = useState(false);
  const closeModalExport = () => updateShowModalExport(false);
  const showExport = () => updateShowModalExport(true);

  function ModalExport() {
    const [selectedOption, setSelectedOption] = useState<string>('');

    const handleOptionChange = (value: string) => {
      setSelectedOption(value);
    };
    return (
        <Modal
            show={showModalExport}
            onHide={closeModalExport}
            fullscreen={'md-down'}
        >
          <Modal.Header closeButton>
            <Modal.Title
                className='ms-3 lead'
                style={{ fontSize: '30px' }}
            >
              Exportar arquivo
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Escolha o tipo de arquivo que deseja:</p>

            <Form.Check
                inline
                label='Excel'
                name='Excel'
                type={'radio'}
                checked={selectedOption === 'Excel'}
                value='Excel'
                onChange={() => handleOptionChange('Excel')}
            />
            <Form.Check
                inline
                label='CSV'
                name='CSV'
                type={'radio'}
                checked={selectedOption === 'CSV'}
                value='CSV'
                onChange={() => handleOptionChange('CSV')}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
                variant='primary'
                size='sm'
                onClick={() => {
                  if (selectedOption === 'Excel') {
                    handleExportExcel();
                  } else {
                    handleExportCSV();
                  }
                  closeModalExport();
                }}
                disabled={selectedOption === ''}
            >
              Exportar
            </Button>
          </Modal.Footer>
        </Modal>
    );
  }

  const handleExportExcel = async () => {
    try {
      const response = await exportFileExcel();

      const url = window.URL.createObjectURL(new Blob([response.body]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Sintetico.xlsx`);
      document.body.appendChild(link);
      link.click();
      link!.parentNode!.removeChild(link);
      showAlert({
        show: true,
        content: 'Exportado com sucesso',
        color: 'success',
        time: 2000,
      });
    } catch (error) {
      console.error('Error downloading Excel file:', error);
    }
  };

  async function handleExportCSV() {
    /** inicia o loading */
    updateLoading(true);
    showAlert({
      show: true,
      content: 'Aguarde um momento',
      color: 'warning',
      time: 2000,
    });
    let response = await exportFileCSV();
    /** dados da requisicao */
    if (response.statusCode !== 500) {
      const url = window.URL.createObjectURL(new Blob([response.body]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Relatorio_Sintetico.csv`);
      document.body.appendChild(link);
      link.click();
      link!.parentNode!.removeChild(link);
      showAlert({
        show: true,
        content: 'Exportado com sucesso',
        color: 'success',
        time: 2000,
      });
    } else
      showAlert({
        show: true,
        content: 'Ops!!! Algo deu errado tente novamente mais tarde...',
        color: 'danger',
        time: 2000,
      });
    /** finaliza o loading */
    updateLoading(false);
  }

  /**
   *
   *
   *
   *
   *  gerar ordem de pedido */
  let modelFilial: any;
  const [showModalGenerateOrder, updateShowModalGenerateOrder] =
      useState(false);
  const closeGenerateOrder = () => updateShowModalGenerateOrder(false);
  const showGenerateOrder = () => updateShowModalGenerateOrder(true);

  async function handleGenerateOrder() {
    /** inicia o loading */
    updateLoading(true);
    /** fecha o modal */
    closeGenerateOrder();
    showAlert({
      show: true,
      content: 'Aguarde um momento',
      color: 'warning',
      time: 2000,
    });
    let response = await generateOrder({
      idFilial: modelFilial === undefined ? undefined : modelFilial.value,
    });
    /** dados da requisicao */
    if (response.statusCode !== 500) {
      const url = window.URL.createObjectURL(
          new Blob([response.body], { type: 'text/csv' }),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Pedido.txt`);
      document.body.appendChild(link);
      link.click();
      link!.parentNode!.removeChild(link);
      showAlert({
        show: true,
        content: 'Pedido gerado com sucesso',
        color: 'success',
        time: 2000,
      });
    } else
      showAlert({
        show: true,
        content: 'Ops!!! Algo deu errado tente novamente mais tarde...',
        color: 'danger',
        time: 2000,
      });
    /** finaliza o loading */
    updateLoading(false);
  }

  function handleValueFilial(filial: {
    value: number;
    label: string
  }) {
    modelFilial = filial;
  }

  function ModalGenerateOrder() {
    return (
        <Modal
            show={showModalGenerateOrder}
            onHide={closeGenerateOrder}
            size={'sm'}
            fullscreen={'md-down'}
        >
          <Modal.Header closeButton>
            <Modal.Title
                className='ms-3 lead'
                style={{ fontSize: '30px' }}
            >
              Gerar Pedido
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group as={Row} className='mb-1'>
              <Form.Label column>Selecione a Filial:</Form.Label>
              <SelectFilial
                  passOnTheValue={handleValueFilial}
                  addKey={{
                    id: null,
                    nome: 'Manter Filial da Demanda',
                  }}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
                variant='primary'
                size='sm'
                onClick={() => handleGenerateOrder()}
            >
              Gerar
            </Button>
          </Modal.Footer>
        </Modal>
    );
  }

  /**
   *
   *
   *
   *
   *  Processar */
  const [showModalProcess, updateShowModalProcess] = useState(false);
  const closeProcess = () => updateShowModalProcess(false);
  const showProcess = () => updateShowModalProcess(true);

  function ModalProcess() {
    return (
        <Modal
            show={showModalProcess}
            onHide={closeProcess}
            fullscreen={'md-down'}
        >
          <Modal.Header closeButton>
            <Modal.Title
                className='ms-3 lead'
                style={{ fontSize: '30px' }}
            >
              Processar Sintético
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group as={Row} className='mb-1'>
              <Form.Label column>
                Selecione pelo menos <b>2</b> Filiais:
              </Form.Label>
              <SelectFilial
                  passOnTheValue={handleValueFilial}
                  isMulti
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
                variant='primary'
                size='sm'
                onClick={() => handleProcessTable()}
            >
              Processar
            </Button>
          </Modal.Footer>
        </Modal>
    );
  }

  /**
   *
   *
   *
   *
   *  planejamento */
  const [showModalPlanning, updateShowModalPlanning] = useState(false);
  const [itemPlanning, updateItemPlanning] = useState<number>(0);
  const closePlanning = () => updateShowModalPlanning(false);
  const showPlanning = () => updateShowModalPlanning(true);

  async function handleApplyPlanning(model: any) {
    /** inicia o loading */
    updateLoading(true);
    /** fecha o modal */
    closePlanning();
    let countPlanning = model.length;
    /** salva no localstorage */
    updateLocalStorege(LOCALSTORAGE_PLANNING, model);
    updateItemPlanning(countPlanning);

    let response = await list({
      pagination: `?page=${0}&size=${totalPageSize}`,
    });
    /** dados da requisicao */
    if (response.statusCode === 200) {
      updateListTableModel(
          response.body.map((value: any) => {
            if (value['dataPesquisa'])
              value['dataPesquisa'] = ParseDateToEn(
                  value['dataPesquisa'],
              );
            return value;
          }),
      );
      changeValueListByColums(response.body);
      if (countPlanning >= 0)
        showAlert({
          show: true,
          content: 'Planejamento Aplicado com sucesso',
          color: 'success',
          time: 2000,
        });
      else
        showAlert({
          show: true,
          content: 'Nenhum item aplicado',
          color: 'warning',
          time: 2000,
        });
    } else
      showAlert({
        show: true,
        content: 'Ops!!! Algo deu errado tente novamente mais tarde...',
        color: 'danger',
        time: 2000,
      });
    /** seta paginacao para inicial */
    if (response.headers['link']) {
      let links: LinkHeader = parseLinkHeader(
          response.headers['link'],
      ) as any;

      updateFirstPagination(links.first);
      updateLastPagination(links.last);
      updateNextPagination(links.next);
      updatePrevPagination(links.prev);

      setValuePaginations(
          0,
          parseInt(getParameterUrlByName('page', links.first)!),
          parseInt(getParameterUrlByName('page', links.last)!),
      );
    }
    /** finaliza o loading */
    updateLoading(false);
  }

  function ModalPlanning() {
    return (
        <Modal
            show={showModalPlanning}
            onHide={closePlanning}
            size={'xl'}
            fullscreen={'md-down'}
        >
          <Modal.Header closeButton>
            <Modal.Title
                className='ms-3 lead'
                style={{ fontSize: '30px' }}
            >
              Planejamento
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <ContainerFilter
                    keyLocalStorage={LOCALSTORAGE_PLANNING}
                    filterLocalStorage={getLocalStorage(
                        LOCALSTORAGE_PLANNING,
                    )}
                    apply={handleApplyPlanning}
                    filtersDefault={optionsFiltersDefault}
                    listAllPlanning={listAllPlanning}
                    listByIdPlanning={function(
                        params?: any,
                    ): Promise<any> {
                      throw new Error(
                          'listByIdPlanning ==> não foi implementada',
                      );
                    }}
                    createPlanning={createPlanning}
                    editPlanning={editPlanning}
                    removePlanning={removePlanning}
                />
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
    );
  }

  /**
   *
   *
   *
   *
   *  colunas das tabelas */
  const [columns, updateColumns] = useState<TableDescriptions[]>(
      ColumsTableSyntheticAdapter,
  );
  const [listTable, updateListTable] = useState<any[]>([]);

  /**
   *
   *
   *
   *
   *  modal icones de alerta */
  const [showModalIcon, updateShowModalIcon] = useState(false);
  const [typeModalIcon, updateTypeModalIcon] = useState<string>();
  const [dataModalIcon, updateDataModalIcon] = useState<ReactNode>(<></>);
  const [dataRowModalIcon, updateDataRowModalIcon] = useState<any>();

  const closeIcon = () => {
    updateShowModalIcon(false);
    updateTypeModalIcon('');
    updateDataModalIcon(<></>);
  };
  const showIcon = () => updateShowModalIcon(true);

  async function handleIconSaldo() {
    let model = [
      {
        type: 'CODIGO_ITEM',
        params: { codigos: [dataRowModalIcon.codigo] },
      },
      {
        type: 'FILIAL',
        params: { filiais: [dataRowModalIcon.filial], type: 'NOT_IN' },
      },
    ];

    let response = await list({
      pagination: `?page=${0}&size=${totalPageSize}`,
      model: model,
    });
    /** dados da requisicao */
    if (response.statusCode !== 500) {
      let data = response.body;
      if (data.length > 0) {
        updateDataModalIcon(
            <div className='my-2'>
              <p>Estoque da Filial:</p>
              <TableHeaderStyled>
                <Table>
                  <thead>
                  <tr>
                    <th style={{ textAlign: 'center' }}>
                      Filial
                    </th>
                    <th style={{ textAlign: 'left' }}>
                      Peça
                    </th>
                    <th style={{ textAlign: 'center' }}>
                      Sufixo
                    </th>
                    <th style={{ textAlign: 'right' }}>
                      Quantidade
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td align='center'>
                      {dataRowModalIcon.filial}
                    </td>
                    <td align='left'>
                      {dataRowModalIcon.nome}
                    </td>
                    <td align='center'>
                      {dataRowModalIcon.codigo}
                    </td>
                    <td align='right'>
                      {dataRowModalIcon.quantidade}
                    </td>
                  </tr>
                  </tbody>
                </Table>
              </TableHeaderStyled>
              <p className='mt-4'>
                Peças disponíveis em outras filiais:
              </p>
              <TableHeaderStyled>
                <Table size='sm' style={{ cursor: 'pointer' }}>
                  <thead>
                  <tr>
                    <th style={{ textAlign: 'center' }}>
                      Base
                    </th>
                    <th style={{ textAlign: 'right' }}>
                      Pend
                    </th>
                    <th style={{ textAlign: 'right' }}>
                      Bloq
                    </th>
                    <th style={{ textAlign: 'center' }}>
                      CVF
                    </th>
                    <th style={{ textAlign: 'left' }}>
                      Status
                    </th>
                    <th style={{ textAlign: 'right' }}>
                      Qtd Disp
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  {data.map((value: any, index: number) => {
                    return (
                        <tr key={index}>
                          <td align='center'>
                            {value.base}
                          </td>
                          <td align='right'>
                            {value.estoquePendente}
                          </td>
                          <td align='right'>
                            {value.bloqueado}
                          </td>
                          <td align='center'>
                            {value.curva}
                          </td>
                          <td align='left'>
                            {value.status}
                          </td>
                          <td align='right'>
                            {value.quantidade}
                          </td>
                        </tr>
                    );
                  })}
                  </tbody>
                </Table>
              </TableHeaderStyled>
            </div>,
        );
      } else {
        updateDataModalIcon(
            <>
              <p>sem dados</p>
            </>,
        );
      }
    } else {
      closeIcon();
      showAlert({
        show: true,
        content: 'Ops!!! Algo deu errado tente novamente mais tarde...',
        color: 'danger',
        time: 2000,
      });
    }
  }

  async function handleGraphicHistory() {
    let response = await graphicHistory(dataRowModalIcon.id);
    /** dados da requisicao */
    if (response.statusCode === 200) {
      let data = response.body.map((value: any) => {
        return {
          name: value.anoMes,
          precoReal: value.precoReal,
          precoReposicao: value.precoReposicao,
          precoVenda: value.precoVenda,
        };
      });

      if (data.length > 0) {
        updateDataModalIcon(<Graphic data={data} />);
      } else {
        updateDataModalIcon(
            <>
              <p>sem dados</p>
            </>,
        );
      }
    } else {
      closeIcon();
      showAlert({
        show: true,
        content: 'Ops!!! Algo deu errado tente novamente mais tarde...',
        color: 'danger',
        time: 2000,
      });
    }
  }

  function Graphic(params: {
    data: any
  }) {
    return (
        <LineChart
            width={750}
            height={300}
            data={params.data}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='name' />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line
              type='monotone'
              dataKey='precoReal'
              stroke='#8884d8'
              activeDot={{ r: 8 }}
          />
          <Line
              type='monotone'
              dataKey='precoReposicao'
              stroke='#79aed2'
          />
          <Line type='monotone' dataKey='precoVenda' stroke='#82ca9d' />
        </LineChart>
    );
  }

  async function handleObservationItem() {
    let response = await observationItem({
      codigo: dataRowModalIcon.codigo,
      filial: dataRowModalIcon.filial,
    });
    /** dados da requisicao */
    if (response.statusCode === 200) {
      if (response.body.length > 0) {
        updateDataModalIcon(
            <TableHeaderStyled>
              <Table className='mt-3'>
                <thead>
                <tr>
                  <th>ID</th>
                  <th>Mensagem</th>
                </tr>
                </thead>
                <tbody>
                {response.body.map(
                    (value: any, index: number) => {
                      return (
                          <tr key={index}>
                            <td>{value.id}</td>
                            <td>{value.mensagem}</td>
                          </tr>
                      );
                    },
                )}
                </tbody>
              </Table>
            </TableHeaderStyled>,
        );
      } else {
        updateDataModalIcon(
            <>
              <p>sem dados</p>
            </>,
        );
      }
    } else {
      closeIcon();
      showAlert({
        show: true,
        content: 'Ops!!! Algo deu errado tente novamente mais tarde...',
        color: 'danger',
        time: 2000,
      });
    }
  }

  async function handlehandleMovement(typeMoviment: string) {
    let response = await movement({
      codigoItem: dataRowModalIcon.codigo,
      idFilial: dataRowModalIcon.filial,
      typeMoviment: typeMoviment,
    });
    /** dados da requisicao */
    if (response.statusCode === 200) {
      if (response.body.length > 0) {
        updateDataModalIcon(
            <TableHeaderStyled>
              <Table className='mt-3'>
                <thead>
                <tr>
                  <th style={{ textAlign: 'center' }}>
                    Filial
                  </th>
                  <th style={{ textAlign: 'right' }}>
                    Quantidade
                  </th>
                  <th style={{ textAlign: 'right' }}>
                    Valor
                  </th>
                </tr>
                </thead>
                <tbody>
                {response.body.map(
                    (value: any, index: number) => {
                      return (
                          <tr key={index}>
                            <td align='center'>
                              {value.filial}
                            </td>
                            <td align='right'>
                              {value.quantidade}
                            </td>
                            <td align='right'>
                              {value.valor}
                            </td>
                          </tr>
                      );
                    },
                )}
                </tbody>
              </Table>
            </TableHeaderStyled>,
        );
      } else {
        updateDataModalIcon(
            <>
              <p>sem dados</p>
            </>,
        );
      }
    } else {
      closeIcon();
      showAlert({
        show: true,
        content: 'Ops!!! Algo deu errado tente novamente mais tarde...',
        color: 'danger',
        time: 2000,
      });
    }
  }

  async function handlehandleCorrelate() {
    let response = await correlate({ codigoItem: dataRowModalIcon.codigo });
    /** dados da requisicao */
    if (response.statusCode === 200) {
      if (response.body.length > 0) {
        updateDataModalIcon(
            <>
              <p>Item(s) Correlatos:</p>
              <TableHeaderStyled>
                <Table className='mt-1'>
                  <thead>
                  <tr>
                    <th style={{ textAlign: 'center' }}>
                      Código
                    </th>
                    <th style={{ textAlign: 'left' }}>
                      Nome
                    </th>
                    <th style={{ textAlign: 'right' }}>
                      Estoque
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  {response.body.map(
                      (value: any, index: number) => {
                        return (
                            <tr key={index}>
                              <td align='center'>
                                {value.codigo}
                              </td>
                              <td align='left'>
                                {value.nome}
                              </td>
                              <td align='right'>
                                {value.estoque}
                              </td>
                            </tr>
                        );
                      },
                  )}
                  </tbody>
                </Table>
              </TableHeaderStyled>
            </>,
        );
      } else {
        updateDataModalIcon(
            <>
              <p>sem dados</p>
            </>,
        );
      }
    } else {
      closeIcon();
      showAlert({
        show: true,
        content: 'Ops!!! Algo deu errado tente novamente mais tarde...',
        color: 'danger',
        time: 2000,
      });
    }
  }

  function ModalIcon() {
    return (
        <Modal show={showModalIcon} onHide={closeIcon} size='lg'>
          <Modal.Header closeButton>
            <Modal.Title>Alerta</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='my-2'>{dataModalIcon}</div>
          </Modal.Body>
        </Modal>
    );
  }

  /**
   *
   *
   *
   *
   *  reordena / disabilita colunas */
  const [showModalColumns, updateShowModalColumns] = useState(false);
  const closeColumn = () => updateShowModalColumns(false);
  const showColumn = () => updateShowModalColumns(true);

  function handleOrderHideColumns(columns: TableDescriptions[]) {
    updateLocalStorege(
        LOCALSTORAGE_COLUMS,
        columns.sort((a, b) => (a.order > b.order ? 1 : -1)),
    );
    updateColumns(columns.sort((a, b) => (a.order > b.order ? 1 : -1))); //tiago
  }

  function ModalOrderHideColumns() {
    return (
        <Modal show={showModalColumns} onHide={closeColumn} size='lg'>
          <Modal.Header closeButton>
            <Modal.Title>Reordenar</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col className='mb-2'>
                <Form.Group as={Row}>
                  <Form.Label column>
                    Quantidade de Item na Tabela:
                  </Form.Label>
                  <Col sm='12' md={4}>
                    <Form.Control
                        type='number'
                        defaultValue={50}
                        onBlur={(e) => {
                          e.preventDefault();
                          totalPageSize = parseInt(
                              e.target.value,
                          );
                        }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Card>
              <Card.Body>
                <p className='mb-0 text-center'>
                  <small>
                    Clique no item deixando-o em azul para que
                    apareça na tabela,{' '}
                  </small>
                </p>
                <p className='mt-0 text-center'>
                  <small>
                    clique e segure no item para arrastá-lo e
                    reordenar as colunas da tabela trocando os
                    itens de lugar{' '}
                  </small>
                </p>
                <DragInDropHideOrderColumsTableComponent
                    columns={columns}
                />
              </Card.Body>
            </Card>
          </Modal.Body>
          <Modal.Footer>
            <Button
                size='sm'
                variant='primary'
                onClick={async () => {
                  closeColumn();
                  handleOrderHideColumns(columns);
                  changeValueListByColums(listTableModel);
                  await handleTable();
                }}
            >
              Aplicar
            </Button>
          </Modal.Footer>
        </Modal>
    );
  }

  /**
   *
   *
   *
   *
   *  demandas */
  const today = new Date();
  const periodsMonths = datePeriodsMonths(today, 7);

  function lastMonth(date: string | number | Date, diff: number) {
    const listMonths = [
      'janeiro',
      'fevereiro',
      'março',
      'abril',
      'maio',
      'junho',
      'julho',
      'agosto',
      'setembro',
      'outubro',
      'novembro',
      'dezembro',
    ];
    const d = new Date(date);
    let ano = d.getFullYear();
    let month = d.getMonth() - diff;
    if (month < 0) {
      month = month + 12;
      ano = ano - 1;
    }
    return `${listMonths[month]}/${ano}`;
  }

  function datePeriodsMonths(
      data: string | number | Date,
      numberOfMonths: number,
  ) {
    const datas = [];
    for (let i = 0; i < numberOfMonths; i++) {
      datas.push(lastMonth(data, i));
    }
    return datas;
  }

  const [listUpdateAll, updateAllTable] = useState<any[]>([]);

  const handleInputBlurQuantity = (customParam: any[], value: any) => (event: React.FocusEvent<HTMLInputElement>) => {
    if (value['quantidade'] !== parseInt(event.target.value, 10)) {
      const newItem = { id: value['id'], filial: value['filial'], quantidade: parseInt(event.target.value, 10) };
      const existingIndex = listUpdateAll.findIndex(item => item.id === newItem.id);
      if (existingIndex !== -1) {
        const updatedArray = [...listUpdateAll];
        updatedArray[existingIndex].quantidade = newItem.quantidade;
        updateAllTable(updatedArray);
      } else {
        updateAllTable([...listUpdateAll, newItem]);
      }
    }

    changeValueListByColums(
        customParam.map(
            (
                val,
            ) => {
              if (
                  val.id ===
                  value[
                      'id'
                      ]
              ) {
                val.quantidade =
                    event.target.value;
              }
              return val;
            },
        ),
    );
  };

  /**
   *
   *
   *
   *
   *  ordenacao de valores da tabela */
  function changeValueListByColums(model: any[]) {
    if (model !== undefined) {
      var ListTableChanged: any[] = [];
      var columsName = columns
          .filter((value) => value.hidden === false)
          .map((vl) => {
            return vl.dataField;
          });

      model.forEach((value) => {
        const filtered = Object.keys(value)
            .filter((key) => columsName.includes(key))
            .reduce((obj: any, key: any) => {
              obj[key] = value[key];
              return obj;
            }, {});

        ListTableChanged.push(filtered);
      });
      updateListTable(ListTableChanged);
    }
  }

  async function orderValueColums(nameColumn: TableDescriptions) {
    /** busca no localstorage */
    let localStorageColumsOrder: any[] =
        getLocalStorage(LOCALSTORAGE_COLUMS_ORDER) ?? [];

    columns.forEach((value) => {
      if (value.id === nameColumn.id) {
        if (localStorageColumsOrder.length === 0) {
          value.ordination!.ordinationValue = 'asc';
          localStorageColumsOrder[0] = value.ordination;
          updateLocalStorege(
              LOCALSTORAGE_COLUMS_ORDER,
              localStorageColumsOrder,
          );
        } else {
          if (value.ordination?.ordinationValue === 'asc') {
            value.ordination!.ordinationValue = 'desc';
            localStorageColumsOrder[0] = value.ordination;
            updateLocalStorege(
                LOCALSTORAGE_COLUMS_ORDER,
                localStorageColumsOrder,
            );
          } else if (value.ordination?.ordinationValue === 'desc') {
            value.ordination!.ordinationValue = '';
            localStorageColumsOrder[0] = value.ordination;
            updateLocalStorege(
                LOCALSTORAGE_COLUMS_ORDER,
                localStorageColumsOrder,
            );
          } else if (value.ordination?.ordinationValue === '') {
            value.ordination!.ordinationValue = 'asc';
            localStorageColumsOrder[0] = value.ordination;
            updateLocalStorege(
                LOCALSTORAGE_COLUMS_ORDER,
                localStorageColumsOrder,
            );
          }
        }
      } else {
        if (
            value.ordination !== undefined &&
            value.ordination !== null
        ) {
          value.ordination!.ordinationValue = '';
        }
      }
    });
    /** chama o endpoint */
    totalPageSize = Number(getParameterUrlByName('size', nextPagination))!;
    await handleTable({
      pagination: `?page=${0}&size=${totalPageSize}`,
      page: 0,
      size: totalPageSize,
    });
  }

  function CreateIconOrder(params?: any) {
    if (params.nameColumn.ordinationValue === 'asc')
      return (
          <FaSortUp
              color='#FFC007'
              size={11}
              key={generateRandomId(5000)}
          />
      );
    else if (params.nameColumn.ordinationValue === 'desc')
      return (
          <FaSortDown
              color='#FFC007'
              size={11}
              key={generateRandomId(5000)}
          />
      );
    else return <FaSort size={11} key={generateRandomId(5000)} />;
  }

  /**
   *
   *
   *
   *
   * controle de estado
   */
  useEffect(() => {
    /** chama o endpoint */
    handleTable();
    const columsLocalStorage = getLocalStorage(LOCALSTORAGE_COLUMS);
    const planningLocalStorage = getLocalStorage(LOCALSTORAGE_PLANNING);

    if (planningLocalStorage)
      updateItemPlanning(planningLocalStorage.length - 1);

    if (columsLocalStorage)
      updateColumns(
          columsLocalStorage.sort(
              (a: {
                order: number
              }, b: {
                order: number
              }) =>
                  a.order > b.order ? 1 : -1,
          ),
      );

    updateLocalStorege(LOCALSTORAGE_COLUMS_ORDER, []);
  }, []);
  useEffect(() => {
    if (typeModalIcon === 'Saldo') handleIconSaldo();
    if (typeModalIcon === 'Histórico') handleGraphicHistory();
    if (typeModalIcon === 'Venda') handlehandleMovement('venda');
    if (typeModalIcon === 'Compra') handlehandleMovement('compra');
    if (typeModalIcon === 'Observacao') handleObservationItem();
    if (typeModalIcon === 'Correlatos') handlehandleCorrelate();
  }, [typeModalIcon, updateTypeModalIcon]);
  useEffect(() => {
    changeValueListByColums(listTableModel);
  }, [listTableModel, updateListTableModel]);

  /**
   *
   * RENDER
   *
   */
  return (
      <>
        <Headers />

        {loading && <Loading />}
        {!loading && (
            <div>
              <div className='mx-5'>
                <TitleGroupComponent titlePage={titlePage}>
                  <Row>
                    <Col className='d-flex align-items-center justify-content-end'>
                      <Button
                          variant='outline-primary'
                          className='d-flex align-items-center'
                          onClick={showPlanning}
                          size='sm'
                      >
                        {itemPlanning > 0 ? (
                            <Badge bg='danger' className='me-2'>
                              {itemPlanning}
                            </Badge>
                        ) : (
                            <></>
                        )}
                        <BsSliders className='me-1' />
                        Planejamento
                      </Button>
                      <Button
                          className='ms-1'
                          variant='outline-primary'
                          onClick={showProcess}
                          size='sm'
                      >
                        Processar
                      </Button>
                      <Button
                          className='ms-1'
                          variant='outline-primary'
                          onClick={showExport}
                          size='sm'
                          disabled={listTable.length === 0}
                      >
                        Exportar
                      </Button>
                      <Button
                          className='ms-1'
                          variant='primary'
                          onClick={showGenerateOrder}
                          size='sm'
                          disabled={listTable.length === 0}
                      >
                        Gerar pedido
                      </Button>
                    </Col>
                  </Row>
                </TitleGroupComponent>
              </div>
              <div className='mx-3'>
                <>
                  <Card>
                    <Card.Body>
                      {listTable.length > 0 ? (
                          <>
                            <div className='d-flex align-items-center justify-content-center'>
                              <Painel panel={panelList} />
                            </div>
                            <div>
                              <Row>
                                <Col className='d-flex align-items-center justify-content-between'>
                                  <Button
                                      variant='outline-primary'
                                      onClick={showColumn}
                                      size={'sm'}
                                      className='d-flex align-items-center'
                                  >
                                    <BiColumns />
                                  </Button>
                                  <div>
                                    <Badge bg='secondary'>
                                      {`Equalização 
                                                                    ${
                                          typeRemanejamento.tipo !==
                                          ''
                                              ? ` - Tipo: ${typeRemanejamento.tipo}`
                                              : ``
                                      }
                                                                `}
                                    </Badge>
                                    <Badge
                                        bg='secondary'
                                        className='ms-2'
                                    >
                                      {lastSearch
                                          ? `Última Pesquisa: ${lastSearch}`
                                          : `Sem dados da última pesquisa`}
                                    </Badge>
                                    <Badge
                                        bg='secondary'
                                        className='ms-2'
                                    >
                                      {lastCharge
                                          ? `Última Carga: ${lastCharge}`
                                          : `Sem dados da última carga`}
                                    </Badge>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                            <div className='mt-3'>
                              <TableHeaderFixedStyled>
                                <Table>
                                  <thead>
                                  <tr className='m-0 p-0'>
                                    {columns.map(
                                        (
                                            value,
                                            index,
                                        ) => {
                                          let align =
                                              value.align;

                                          let classNameDiv = `d-flex align-items-center justify-content-${align} mx-2`;
                                          if (
                                              align ===
                                              'left'
                                          )
                                            classNameDiv = `d-flex align-items-center justify-content-start mx-2`;
                                          if (
                                              align ===
                                              'right'
                                          )
                                            classNameDiv = `d-flex align-items-center justify-content-end mx-2`;

                                          if (
                                              value.hidden ===
                                              false
                                          ) {
                                            if (value.dataField === 'demandas') {
                                              return periodsMonths.map(
                                                  (month, indexMonth) => {
                                                    return (
                                                        <th key={indexMonth}>
                                                          <div className='d-flex align-items-center'>
                                                            <p style={{ whiteSpace: 'nowrap' }} className='m-2'>
                                                              {month}
                                                            </p>
                                                          </div>
                                                        </th>
                                                    );
                                                  },
                                              );
                                            }

                                            if (
                                                value.dataField ===
                                                'alterado'
                                            ) {
                                              return undefined;
                                            }

                                            if (
                                                value.dataField ===
                                                'bloquadoCompra'
                                            ) {
                                              return undefined;
                                            }

                                            if (
                                                value.dataField ===
                                                'observacao'
                                            ) {
                                              return undefined;
                                            }

                                            if (
                                                value.dataField ===
                                                'id'
                                            ) {
                                              return undefined;
                                            }

                                            return (
                                                <>
                                                  {value.ordination ? (
                                                      <th
                                                          align={
                                                            value.align
                                                          }
                                                          className='px-1'
                                                          key={
                                                            index
                                                          }
                                                          onClick={() => {
                                                            orderValueColums(
                                                                value,
                                                            );
                                                          }}
                                                      >
                                                        <div
                                                            className={
                                                              classNameDiv
                                                            }
                                                        >
                                                          <div className='d-flex align-items-center justify-content-center'>
                                                            <p
                                                                style={{
                                                                  whiteSpace:
                                                                      'nowrap',
                                                                }}
                                                                className='m-1'
                                                            >
                                                              {
                                                                value.text
                                                              }
                                                            </p>
                                                            <CreateIconOrder
                                                                nameColumn={
                                                                  value.ordination
                                                                }
                                                            />
                                                          </div>
                                                        </div>
                                                      </th>
                                                  ) : (
                                                      <th
                                                          align={
                                                            value.align
                                                          }
                                                          className='px-1'
                                                          key={
                                                            index
                                                          }
                                                      >
                                                        <div
                                                            className={
                                                              classNameDiv
                                                            }
                                                        >
                                                          <div className='d-flex align-items-center justify-content-center'>
                                                            <p
                                                                style={{
                                                                  whiteSpace:
                                                                      'nowrap',
                                                                }}
                                                                className='m-1'
                                                            >
                                                              {
                                                                value.text
                                                              }
                                                            </p>
                                                          </div>
                                                        </div>
                                                      </th>
                                                  )}
                                                </>
                                            );
                                          }
                                        },
                                    )}
                                  </tr>
                                  </thead>
                                  <tbody>
                                  {listTable.map(
                                      (
                                          value,
                                          index,
                                      ) => {
                                        return (
                                            <tr
                                                key={
                                                  index
                                                }
                                                className='text-center'
                                            >
                                              {columns.map(
                                                  (
                                                      vc,
                                                      i,
                                                  ) => {
                                                    if (
                                                        vc.dataField ===
                                                        'ALERTA'
                                                    ) {
                                                      const sizeIcon = 15;
                                                      const delayShow = 1500;
                                                      const delayHide = 500;

                                                      const elements: React.ReactElement[] =
                                                          [
                                                            <div
                                                                key={
                                                                    i +
                                                                    1
                                                                }
                                                            >
                                                              <Button
                                                                  data-tip
                                                                  data-for='saldo'
                                                                  variant='secondary'
                                                                  size='sm'
                                                                  className='m-0 p-0 bg-white border-0'
                                                                  onClick={() => {
                                                                    showIcon();
                                                                    updateTypeModalIcon(
                                                                        'Saldo',
                                                                    );
                                                                    updateDataRowModalIcon(
                                                                        value,
                                                                    );
                                                                  }}
                                                              >
                                                                <MdMapsHomeWork
                                                                    size={
                                                                      sizeIcon
                                                                    }
                                                                    color='#017BFE'
                                                                />
                                                              </Button>
                                                              <ReactTooltip
                                                                  id='saldo'
                                                                  delayShow={
                                                                    delayShow
                                                                  }
                                                                  delayHide={
                                                                    delayHide
                                                                  }
                                                                  backgroundColor={
                                                                    '#017BFE'
                                                                  }
                                                                  border
                                                              >
                                                                                                        <span>
                                                                                                            Saldo
                                                                                                            em
                                                                                                            outras
                                                                                                            lojas
                                                                                                        </span>
                                                              </ReactTooltip>
                                                            </div>,
                                                            <div
                                                                key={
                                                                    i +
                                                                    2
                                                                }
                                                            >
                                                              <Button
                                                                  data-tip
                                                                  data-for='historico'
                                                                  variant='secondary'
                                                                  size='sm'
                                                                  className='m-0 p-0 bg-white border-0'
                                                                  onClick={() => {
                                                                    showIcon();
                                                                    updateTypeModalIcon(
                                                                        'Histórico',
                                                                    );
                                                                    updateDataRowModalIcon(
                                                                        value,
                                                                    );
                                                                  }}
                                                              >
                                                                <MdAttachMoney
                                                                    size={
                                                                      sizeIcon
                                                                    }
                                                                    color='#017BFE'
                                                                />
                                                              </Button>
                                                              <ReactTooltip
                                                                  id='historico'
                                                                  type='warning'
                                                                  delayShow={
                                                                    delayShow
                                                                  }
                                                                  delayHide={
                                                                    delayHide
                                                                  }
                                                                  backgroundColor={
                                                                    '#017BFE'
                                                                  }
                                                                  border
                                                              >
                                                                                                        <span>
                                                                                                            Histórico
                                                                                                            de
                                                                                                            preço
                                                                                                        </span>
                                                              </ReactTooltip>
                                                            </div>,
                                                            <div
                                                                key={
                                                                    i +
                                                                    2
                                                                }
                                                            >
                                                              <Button
                                                                  data-tip
                                                                  data-for='correlatos'
                                                                  variant='secondary'
                                                                  size='sm'
                                                                  className='m-0 p-0 bg-white border-0'
                                                                  onClick={() => {
                                                                    showIcon();
                                                                    updateTypeModalIcon(
                                                                        'Correlatos',
                                                                    );
                                                                    updateDataRowModalIcon(
                                                                        value,
                                                                    );
                                                                  }}
                                                              >
                                                                <TbSitemap
                                                                    size={
                                                                      sizeIcon
                                                                    }
                                                                    color='#DC3444'
                                                                />
                                                              </Button>
                                                              <ReactTooltip
                                                                  id='correlatos'
                                                                  type='warning'
                                                                  delayShow={
                                                                    delayShow
                                                                  }
                                                                  delayHide={
                                                                    delayHide
                                                                  }
                                                                  backgroundColor={
                                                                    '#DC3444'
                                                                  }
                                                                  border
                                                              >
                                                                                                        <span>
                                                                                                            Items
                                                                                                            correlatos
                                                                                                        </span>
                                                              </ReactTooltip>
                                                            </div>,
                                                          ];

                                                      if (value['observacao'] !== null) {
                                                        if (value['observacao'] === 1) {
                                                          elements.push(
                                                              <div key={i + 3}>
                                                                <Button
                                                                    data-tip
                                                                    data-for="observacao1"
                                                                    variant="secondary"
                                                                    size="sm"
                                                                    className="m-0 p-0 bg-white border-0"
                                                                    onClick={() => {
                                                                      showIcon();
                                                                      updateTypeModalIcon('Observacao');
                                                                      updateDataRowModalIcon(value);
                                                                    }}
                                                                >
                                                                  <FiAlertTriangle size={sizeIcon} color="#017BFE" />
                                                                </Button>
                                                                <ReactTooltip
                                                                    id="observacao1"
                                                                    type="warning"
                                                                    delayShow={delayShow}
                                                                    delayHide={delayHide}
                                                                    backgroundColor="#017BFE"
                                                                    border
                                                                >
                                                                  <span>Infomação</span>
                                                                </ReactTooltip>
                                                              </div>
                                                          );
                                                        }
                                                        if (value['observacao'] === 2) {
                                                          elements.push(
                                                              <div key={i + 3}>
                                                                <Button
                                                                    data-tip
                                                                    data-for="observacao2"
                                                                    variant="secondary"
                                                                    size="sm"
                                                                    className="m-0 p-0 bg-white border-0"
                                                                    onClick={() => {
                                                                      showIcon();
                                                                      updateTypeModalIcon('Observacao');
                                                                      updateDataRowModalIcon(value);
                                                                    }}
                                                                >
                                                                  <GrCircleInformation size={sizeIcon} color="#017BFE" />
                                                                </Button>
                                                                <ReactTooltip
                                                                    id="observacao2"
                                                                    type="warning"
                                                                    delayShow={delayShow}
                                                                    delayHide={delayHide}
                                                                    backgroundColor="#017BFE"
                                                                    border
                                                                >
                                                                  <span>Alerta</span>
                                                                </ReactTooltip>
                                                              </div>
                                                          );
                                                        }
                                                        if (value['observacao'] === 3) {
                                                          elements.push(
                                                              <div key={i + 3}>
                                                                <Button
                                                                    data-tip
                                                                    data-for="observacao3"
                                                                    variant="secondary"
                                                                    size="sm"
                                                                    className="m-0 p-0 bg-white border-0"
                                                                    onClick={() => {
                                                                      showIcon();
                                                                      updateTypeModalIcon('Observacao');
                                                                      updateDataRowModalIcon(value);
                                                                    }}
                                                                >
                                                                  <GrStatusCritical size={sizeIcon} color="#017BFE" />
                                                                </Button>
                                                                <ReactTooltip
                                                                    id="observacao3"
                                                                    type="warning"
                                                                    delayShow={delayShow}
                                                                    delayHide={delayHide}
                                                                    backgroundColor="#017BFE"
                                                                    border
                                                                >
                                                                  <span>Crítico</span>
                                                                </ReactTooltip>
                                                              </div>
                                                          );
                                                        }
                                                        if (value['observacao'] === 4) {
                                                          elements.push(
                                                              <div key={i + 3}>
                                                                <Button
                                                                    data-tip
                                                                    data-for="observacao4"
                                                                    variant="secondary"
                                                                    size="sm"
                                                                    className="m-0 p-0 bg-white border-0"
                                                                    onClick={() => {
                                                                      showIcon();
                                                                      updateTypeModalIcon('Observacao');
                                                                      updateDataRowModalIcon(value);
                                                                    }}
                                                                >
                                                                  <BsTag size={sizeIcon} color="#017BFE" />
                                                                </Button>
                                                                <ReactTooltip
                                                                    id="observacao4"
                                                                    type="warning"
                                                                    delayShow={delayShow}
                                                                    delayHide={delayHide}
                                                                    backgroundColor="#017BFE"
                                                                    border
                                                                >
                                                                  <span>Oferta Aberta</span>
                                                                </ReactTooltip>
                                                              </div>
                                                          );
                                                        }
                                                        if (value['observacao'] === 5) {
                                                          elements.push(
                                                              <div key={i + 3}>
                                                                <Button
                                                                    data-tip
                                                                    data-for="observacao5"
                                                                    variant="secondary"
                                                                    size="sm"
                                                                    className="m-0 p-0 bg-white border-0"
                                                                    onClick={() => {
                                                                      showIcon();
                                                                      updateTypeModalIcon('Observacao');
                                                                      updateDataRowModalIcon(value);
                                                                    }}
                                                                >
                                                                  <BsFillCartFill size={sizeIcon} color="#017BFE" />
                                                                </Button>
                                                                <ReactTooltip
                                                                    id="observacao5"
                                                                    type="warning"
                                                                    delayShow={delayShow}
                                                                    delayHide={delayHide}
                                                                    backgroundColor="#017BFE"
                                                                    border
                                                                >
                                                                  <span>Oferta Pedido</span>
                                                                </ReactTooltip>
                                                              </div>
                                                          );
                                                        }
                                                        if (value['observacao'] === 6) {
                                                          elements.push(
                                                              <div key={i + 3}>
                                                                <Button
                                                                    data-tip
                                                                    data-for="observacao6"
                                                                    variant="secondary"
                                                                    size="sm"
                                                                    className="m-0 p-0 bg-white border-0"
                                                                    onClick={() => {
                                                                      showIcon();
                                                                      updateTypeModalIcon('Observacao');
                                                                      updateDataRowModalIcon(value);
                                                                    }}
                                                                >
                                                                  <HiBell size={sizeIcon} color="#017BFE" />
                                                                </Button>
                                                                <ReactTooltip
                                                                    id="observacao6"
                                                                    type="warning"
                                                                    delayShow={delayShow}
                                                                    delayHide={delayHide}
                                                                    backgroundColor="#017BFE"
                                                                    border
                                                                >
                                                                  <span>Alerta Automático</span>
                                                                </ReactTooltip>
                                                              </div>
                                                          );
                                                        }
                                                      }


                                                      if (
                                                          value[
                                                              'percentualDesconto'
                                                              ] >
                                                          0
                                                      ) {
                                                        elements.push(
                                                            <div
                                                                key={
                                                                    i +
                                                                    4
                                                                }
                                                            >
                                                              <Button
                                                                  data-tip
                                                                  data-for='desconto'
                                                                  variant='secondary'
                                                                  size='sm'
                                                                  className='m-0 p-0 bg-white border-0'
                                                                  onClick={() => {
                                                                    showIcon();
                                                                    updateTypeModalIcon(
                                                                        'Desconto',
                                                                    );
                                                                    updateDataRowModalIcon(
                                                                        value,
                                                                    );
                                                                  }}
                                                              >
                                                                <BsPercent
                                                                    size={
                                                                      sizeIcon
                                                                    }
                                                                    color='#017BFE'
                                                                />
                                                              </Button>
                                                              <ReactTooltip
                                                                  id='desconto'
                                                                  type='warning'
                                                                  delayShow={
                                                                    delayShow
                                                                  }
                                                                  delayHide={
                                                                    delayHide
                                                                  }
                                                                  backgroundColor={
                                                                    '#017BFE'
                                                                  }
                                                                  border
                                                              >
                                                                                                        <span>
                                                                                                            Desconto
                                                                                                        </span>
                                                              </ReactTooltip>
                                                            </div>,
                                                        );
                                                      }

                                                      if (
                                                          value[
                                                              'curva'
                                                              ] ===
                                                          'D1' ||
                                                          value[
                                                              'curva'
                                                              ] ===
                                                          'D2' ||
                                                          value[
                                                              'curva'
                                                              ] ===
                                                          'D3' ||
                                                          value[
                                                              'curva'
                                                              ] ===
                                                          'D4' ||
                                                          value[
                                                              'curva'
                                                              ] ===
                                                          'N1' ||
                                                          value[
                                                              'curva'
                                                              ] ===
                                                          'N2' ||
                                                          value[
                                                              'curva'
                                                              ] ===
                                                          'N3' ||
                                                          value[
                                                              'curva'
                                                              ] ===
                                                          'N4'
                                                      ) {
                                                        if (
                                                            loggedUserDataRole.includes(
                                                                'IS_OBSOLETOS',
                                                            )
                                                        ) {
                                                          elements.push(
                                                              <div
                                                                  key={
                                                                      i +
                                                                      5
                                                                  }
                                                              >
                                                                <Button
                                                                    size='sm'
                                                                    variant='secondary'
                                                                    data-tip
                                                                    data-for='venda'
                                                                    className='m-0 p-0 bg-white border-0'
                                                                    onClick={() => {
                                                                      showIcon();
                                                                      updateTypeModalIcon(
                                                                          'Venda',
                                                                      );
                                                                      updateDataRowModalIcon(
                                                                          value,
                                                                      );
                                                                    }}
                                                                >
                                                                  <FaArrowRight
                                                                      size={
                                                                        sizeIcon
                                                                      }
                                                                      color={
                                                                        '#FFC007'
                                                                      }
                                                                  />
                                                                </Button>
                                                                <ReactTooltip
                                                                    id='venda'
                                                                    type='warning'
                                                                    delayShow={
                                                                      delayShow
                                                                    }
                                                                    delayHide={
                                                                      delayHide
                                                                    }
                                                                    backgroundColor={
                                                                      '#FFC007'
                                                                    }
                                                                    border
                                                                >
                                                                                                            <span>
                                                                                                                Venda
                                                                                                            </span>
                                                                </ReactTooltip>
                                                              </div>,
                                                          );
                                                        }
                                                      } else if (
                                                          value[
                                                              'curva'
                                                              ] ===
                                                          'A1' ||
                                                          value[
                                                              'curva'
                                                              ] ===
                                                          'A2' ||
                                                          value[
                                                              'curva'
                                                              ] ===
                                                          'A3' ||
                                                          value[
                                                              'curva'
                                                              ] ===
                                                          'A4' ||
                                                          value[
                                                              'curva'
                                                              ] ===
                                                          'B1' ||
                                                          value[
                                                              'curva'
                                                              ] ===
                                                          'B2' ||
                                                          value[
                                                              'curva'
                                                              ] ===
                                                          'B3' ||
                                                          value[
                                                              'curva'
                                                              ] ===
                                                          'B4' ||
                                                          value[
                                                              'curva'
                                                              ] ===
                                                          'C1' ||
                                                          value[
                                                              'curva'
                                                              ] ===
                                                          'C2' ||
                                                          value[
                                                              'curva'
                                                              ] ===
                                                          'C3' ||
                                                          value[
                                                              'curva'
                                                              ] ===
                                                          'C4'
                                                      ) {
                                                        if (
                                                            loggedUserDataRole.includes(
                                                                'IS_OBSOLETOS',
                                                            )
                                                        ) {
                                                          elements.push(
                                                              <div
                                                                  key={
                                                                      i +
                                                                      5
                                                                  }
                                                              >
                                                                <Button
                                                                    size='sm'
                                                                    variant='secondary'
                                                                    data-tip
                                                                    data-for='compra'
                                                                    className='m-0 p-0 bg-white border-0'
                                                                    onClick={() => {
                                                                      showIcon();
                                                                      updateTypeModalIcon(
                                                                          'Compra',
                                                                      );
                                                                      updateDataRowModalIcon(
                                                                          value,
                                                                      );
                                                                    }}
                                                                >
                                                                  <FaArrowLeft
                                                                      size={
                                                                        sizeIcon
                                                                      }
                                                                      color={
                                                                        '#FFC007'
                                                                      }
                                                                  />
                                                                </Button>
                                                                <ReactTooltip
                                                                    id='compra'
                                                                    type='warning'
                                                                    delayShow={
                                                                      delayShow
                                                                    }
                                                                    delayHide={
                                                                      delayHide
                                                                    }
                                                                    backgroundColor={
                                                                      '#28A745'
                                                                    }
                                                                    border
                                                                >
                                                                                                            <span>
                                                                                                                Compra
                                                                                                            </span>
                                                                </ReactTooltip>
                                                              </div>,
                                                          );
                                                        }
                                                      }

                                                      return (
                                                          <td
                                                              key={
                                                                i
                                                              }
                                                              className='m-0 p-0'
                                                          >
                                                            <div
                                                                className='d-flex align-items-center justify-content-start m-0 p-0  my-1'>
                                                              <div
                                                                  key={
                                                                    i
                                                                  }
                                                                  className='d-flex align-items-center justify-content-start'
                                                              >
                                                                {
                                                                  elements
                                                                }
                                                              </div>
                                                            </div>
                                                          </td>
                                                      );
                                                    }

                                                    if (
                                                        vc.dataField ===
                                                        'quantidade'
                                                    ) {
                                                      let borderWidth =
                                                          value[
                                                              'alterado'
                                                              ]
                                                              ? 3
                                                              : 1;
                                                      let borderColor =
                                                          value[
                                                              'alterado'
                                                              ]
                                                              ? '#32B529'
                                                              : '';

                                                      return (
                                                          <td
                                                              key={
                                                                i
                                                              }
                                                              className='m-0 p-0 mx-2'
                                                          >
                                                            <div className='d-flex align-items-center m-0 p-0 mx-2'>
                                                              <div>
                                                                <Form.Control
                                                                    disabled={
                                                                      value[
                                                                          'bloquadoCompra'
                                                                          ]
                                                                    }
                                                                    className={`m-0 p-0 mx-2`}
                                                                    type='text'
                                                                    defaultValue={
                                                                      value[
                                                                          'quantidade'
                                                                          ]
                                                                    }
                                                                    size='sm'
                                                                    style={{
                                                                      width: 50,
                                                                      borderWidth:
                                                                      borderWidth,
                                                                      borderColor:
                                                                      borderColor,
                                                                    }}
                                                                    onBlur={
                                                                      handleInputBlurQuantity(listTableModel, value)
                                                                    }
                                                                />
                                                              </div>
                                                              {value[
                                                                  'bloquadoCompra'
                                                                  ] && (
                                                                  <>
                                                                    <AiFillLock
                                                                        data-tip
                                                                        data-for='bloqueado'
                                                                        size={
                                                                          18
                                                                        }
                                                                        className='m-1 ms-2'
                                                                        color={
                                                                          '#6C757D'
                                                                        }
                                                                    />
                                                                    <ReactTooltip
                                                                        id='bloqueado'
                                                                        delayShow={
                                                                          2000
                                                                        }
                                                                        backgroundColor={
                                                                          '#6C757D'
                                                                        }
                                                                        border
                                                                    >
                                                                                                                <span>
                                                                                                                    Item
                                                                                                                    bloqueado
                                                                                                                    para
                                                                                                                    compra
                                                                                                                </span>
                                                                    </ReactTooltip>
                                                                  </>
                                                              )}
                                                              {!value[
                                                                  'bloquadoCompra'
                                                                  ] && (
                                                                  <>
                                                                    <BsCheckLg
                                                                        data-tip
                                                                        data-for='salvar'
                                                                        className='m-1 ms-2'
                                                                        color={
                                                                          '#28A745'
                                                                        }
                                                                        onClick={() => {
                                                                          handleSaveItem(
                                                                              value,
                                                                          );
                                                                        }}
                                                                        style={{
                                                                          fontSize: 10,
                                                                        }}
                                                                    />
                                                                    <ReactTooltip
                                                                        id='salvar'
                                                                        delayShow={
                                                                          2000
                                                                        }
                                                                        backgroundColor={
                                                                          '#28A745'
                                                                        }
                                                                        border
                                                                    >
                                                                                                                <span>
                                                                                                                    Salvar
                                                                                                                    este
                                                                                                                    item
                                                                                                                </span>
                                                                    </ReactTooltip>

                                                                    <BsXLg
                                                                        data-tip
                                                                        data-for='deletar'
                                                                        className='m-1 ms-1'
                                                                        color={
                                                                          '#DC3444'
                                                                        }
                                                                        onClick={() =>
                                                                            handleDelete(
                                                                                value,
                                                                            )
                                                                        }
                                                                        style={{
                                                                          fontSize: 10,
                                                                        }}
                                                                    />
                                                                    <ReactTooltip
                                                                        id='deletar'
                                                                        delayShow={
                                                                          2000
                                                                        }
                                                                        backgroundColor={
                                                                          '#DC3444'
                                                                        }
                                                                        border
                                                                    >
                                                                                                                <span>
                                                                                                                    Remover
                                                                                                                    Qtd.
                                                                                                                    Salvas
                                                                                                                </span>
                                                                    </ReactTooltip>
                                                                  </>
                                                              )}
                                                            </div>
                                                          </td>
                                                      );
                                                    }

                                                    if (
                                                        vc.dataField ===
                                                        'alterado'
                                                    ) {
                                                      return undefined;
                                                    }

                                                    if (
                                                        vc.dataField ===
                                                        'observacao'
                                                    ) {
                                                      return undefined;
                                                    }

                                                    if (
                                                        vc.dataField ===
                                                        'id'
                                                    ) {
                                                      return undefined;
                                                    }

                                                    if (
                                                        vc.hidden ===
                                                        false
                                                    ) {
                                                      if (
                                                          value[
                                                              vc
                                                                  .dataField
                                                              ] ===
                                                          null ||
                                                          value[
                                                              vc
                                                                  .dataField
                                                              ] ===
                                                          undefined
                                                      ) {
                                                        return (
                                                            <td
                                                                key={
                                                                  i
                                                                }
                                                                className='m-0 p-0 mx-2'
                                                                style={{
                                                                  textAlign:
                                                                      'center',
                                                                  whiteSpace:
                                                                      'nowrap',
                                                                  fontWeight: 250,
                                                                }}
                                                            >
                                                              <div className='m-0 p-0 mx-3 my-1'>
                                                                {
                                                                  ''
                                                                }
                                                              </div>
                                                            </td>
                                                        );
                                                      }

                                                      if (
                                                          typeof value[
                                                              vc
                                                                  .dataField
                                                              ] ===
                                                          'number'
                                                      ) {
                                                        let formatted =
                                                            value[
                                                                vc
                                                                    .dataField
                                                                ];
                                                        if (
                                                            vc.numberFormat ===
                                                            'currency'
                                                        ) {
                                                          formatted =
                                                              Intl.NumberFormat(
                                                                  'pt-BR',
                                                                  {
                                                                    style: 'currency',
                                                                    currency:
                                                                        'BRL',
                                                                  },
                                                              ).format(
                                                                  formatted,
                                                              );
                                                        }
                                                        return (
                                                            <td
                                                                key={
                                                                  i
                                                                }
                                                                className='m-0 p-0 mx-2'
                                                                style={{
                                                                  textAlign:
                                                                  vc.align,
                                                                  whiteSpace:
                                                                      'nowrap',
                                                                  fontWeight: 250,
                                                                }}
                                                            >
                                                              <div className='m-0 p-0 mx-3 my-1'>
                                                                {
                                                                  formatted
                                                                }
                                                              </div>
                                                            </td>
                                                        );
                                                      }

                                                      if (
                                                          typeof value[
                                                              vc
                                                                  .dataField
                                                              ] ===
                                                          'string'
                                                      ) {
                                                        return (
                                                            <td
                                                                key={
                                                                  i
                                                                }
                                                                className='m-0 p-0 mx-2'
                                                                style={{
                                                                  textAlign:
                                                                  vc.align,
                                                                  whiteSpace:
                                                                      'nowrap',
                                                                  fontWeight: 250,
                                                                }}
                                                            >
                                                              <div className='m-0 p-0 mx-3 my-1'>
                                                                {
                                                                  value[
                                                                      vc
                                                                          .dataField
                                                                      ]
                                                                }
                                                              </div>
                                                            </td>
                                                        );
                                                      }

                                                      if (vc.dataField === 'demandas') {
                                                        return value['demandas'].map(
                                                            (month: any, indexMonth: number) => {
                                                              return (
                                                                  <td key={indexMonth} className='m-0 p-0'
                                                                      style={{ textAlign: vc.align, whiteSpace: 'nowrap', fontWeight: 250 }}>
                                                                    {month}
                                                                  </td>
                                                              );
                                                            },
                                                        );
                                                      }

                                                    }
                                                  },
                                              )}
                                            </tr>
                                        );
                                      },
                                  )}
                                  </tbody>
                                </Table>
                              </TableHeaderFixedStyled>
                            </div>
                            <div className='mt-4'>
                              <Row className='d-flex align-items-start justify-content-between'>
                                <Col className='d-flex justify-content-start'></Col>
                                <Col>
                                  <div className='d-flex justify-content-center'>
                                    <Pagination size='sm'>
                                      <Pagination.First
                                          onClick={
                                            onClickFirstPagination
                                          }
                                      >
                                        Primeira
                                      </Pagination.First>
                                      <Pagination.Prev
                                          onClick={
                                            onClickPrevPagination
                                          }
                                      >
                                        Anterior
                                      </Pagination.Prev>
                                      {paginationItemList?.map(
                                          (
                                              value,
                                              key,
                                          ) => {
                                            return (
                                                <Pagination.Item
                                                    key={
                                                      key
                                                    }
                                                    active={
                                                      value.active
                                                    }
                                                    onClick={() =>
                                                        onClickItemPagination(
                                                            value,
                                                        )
                                                    }
                                                >
                                                  {value.item +
                                                      1}
                                                </Pagination.Item>
                                            );
                                          },
                                      )}
                                      <Pagination.Next
                                          onClick={
                                            onClickNextPagination
                                          }
                                      >
                                        Próxima
                                      </Pagination.Next>
                                      <Pagination.Last
                                          onClick={
                                            onClickLastPagination
                                          }
                                      >
                                        Última
                                      </Pagination.Last>
                                    </Pagination>
                                  </div>
                                </Col>
                                <Col className='d-flex justify-content-end'>
                                  <Button
                                      variant='outline-danger'
                                      onClick={() => {
                                        handleDeletePage();
                                        updateLoading(
                                            true,
                                        );
                                      }}
                                      size={'sm'}
                                  >
                                    Remover itens da
                                    página
                                  </Button>

                                  <DropdownButton title='Salvar' style={{ paddingLeft: '5px' }}>
                                    <Button
                                        className='align-items-center ms-1'
                                        style={{ width: '95%', padding: '1px' }}
                                        variant='outline-primary'
                                        onClick={() => {
                                          handleSavePage();
                                          updateLoading(
                                              true,
                                          );
                                        }}
                                        size={'sm'}
                                    >
                                      Página Atual
                                    </Button>
                                    <Button
                                        className='align-items-center ms-1'
                                        style={{ width: '95%', padding: '1px' }}
                                        variant='outline-primary'
                                        onClick={() => {
                                          handleSaveAlteration();
                                          updateLoading(
                                              true,
                                          );
                                        }}
                                        size={'sm'}
                                    >
                                      Alterações
                                    </Button>
                                    <Button
                                        className='align-items-center ms-1'
                                        style={{ width: '95%', padding: '1px' }}
                                        variant='outline-primary'
                                        onClick={
                                          handleSaveAll
                                        }
                                        size={'sm'}
                                    >
                                      Tudo
                                    </Button>

                                  </DropdownButton>
                                </Col>
                              </Row>
                            </div>
                          </>
                      ) : (
                          <>
                            <div
                                className='d-flex align-items-center justify-content-center'
                                style={{ height: '60vh' }}
                            >
                              <p className='m-5'>
                                A busca não retorna nada,
                                verifique o Planejamento ou
                                clique em Processar
                              </p>
                            </div>
                            <div className='mt-4'>
                              <Row className='d-flex align-items-center justify-content-between'>
                                <Col className='d-flex align-items-center justify-content-start'></Col>
                                <Col className='d-flex align-items-center justify-content-center'>
                                  <Pagination size='sm'>
                                    <Pagination.First
                                        onClick={
                                          onClickFirstPagination
                                        }
                                    >
                                      Primeira
                                    </Pagination.First>
                                    <Pagination.Prev
                                        onClick={
                                          onClickPrevPagination
                                        }
                                    >
                                      Anterior
                                    </Pagination.Prev>
                                    {paginationItemList?.map(
                                        (
                                            value,
                                            key,
                                        ) => {
                                          return (
                                              <Pagination.Item
                                                  key={
                                                    key
                                                  }
                                                  active={
                                                    value.active
                                                  }
                                                  onClick={() =>
                                                      onClickItemPagination(
                                                          value,
                                                      )
                                                  }
                                              >
                                                {value.item +
                                                    1}
                                              </Pagination.Item>
                                          );
                                        },
                                    )}
                                    <Pagination.Next
                                        onClick={
                                          onClickNextPagination
                                        }
                                    >
                                      Próxima
                                    </Pagination.Next>
                                    <Pagination.Last
                                        onClick={
                                          onClickLastPagination
                                        }
                                    >
                                      Última
                                    </Pagination.Last>
                                  </Pagination>
                                </Col>
                                <Col className='d-flex justify-content-end'>
                                  <Button
                                      variant='outline-danger'
                                      size={'sm'}
                                      disabled
                                  >
                                    Remover itens da
                                    página
                                  </Button>
                                  <Button
                                      className='ms-1'
                                      variant='outline-primary'
                                      size={'sm'}
                                      disabled
                                  >
                                    Salvar Página
                                  </Button>
                                  <Button
                                      className='d-flex align-items-center ms-1'
                                      variant='outline-primary'
                                      size={'sm'}
                                      disabled
                                  >
                                    Salvar Tudo
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                          </>
                      )}
                    </Card.Body>
                  </Card>
                </>
              </div>
              <Footer />
            </div>
        )}

        <ModalOrderHideColumns />
        <ModalGenerateOrder />
        <ModalProcess />
        <ModalPlanning />
        <ModalIcon />
        <ModalExport />
      </>
  );
};

export default SyntheticPage;
